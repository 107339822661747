<template>
  <section class="highlighted-products-admin scroll-content">
    <h3 class="md-display-1">{{ $translate("highlighted_products.title") }}</h3>

    <section_description_preview :dense="true">
      <div class="md-body-1">{{ $translate("highlighted_products.message") }}</div>
    </section_description_preview>

    <div v-if="can_user_manage_content" class="highlighted-products-admin__buttons">
      <md-button
        data-cy="select-highlighted-products"
        class="md-raised md-primary"
        @click="open_modal = true"
      >
        {{ $translate("highlighted_products.select_product") }}
      </md-button>
    </div>

    <div v-if="get_highlighted_products.length">
      <p class="md-headline">{{ $translate("popular_products.selected_products") }}:</p>
      <draggable v-model="get_highlighted_products" group="get_highlighted_products" @start="drag=true" @end="drag=false">
        <transition-group>
          <div
            class="selected-product-preview md-elevation-3"
            v-for="product in get_highlighted_products"
            :key="product.buffer_id || product.id"
          >
            <img :src="get_correct_first_image(product)" :alt="get_title(product)">
            <p>{{ get_title(product) }}</p>
          </div>
        </transition-group>
      </draggable>
    </div>

    <entity_selection_modal
      v-if="can_user_manage_content"
      modal_title="Add popular products"
      :open_modal="open_modal"
      :enable_collection_selection="false"
      :index_or_id_of_selected_entity="highlighted_products_data"
      @entity_selected="product_selected"
      @toggle_modal="open_modal = !open_modal"
    />
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import draggable from "vuedraggable"
import { CONTENT_STORE, ADMIN, PRODUCTS_STORE, CONFIGURATION_STORE, shopify, imported, ADMIN_STORE } from "../../../constants/others_constants"
import { UPDATE_HIGHLIGHTED_PRODUCTS, UPDATE_HIGHLIGHTED_PRODUCTS_POSITIONING } from "../../../stores/Admin/content/constants"
import { CONTENT_DATA_UPDATED } from "../../../stores/Admin/constants"
import entity_selection_modal from "../entity_selection_modal"
import section_description_preview from "../section_description_preview"
import get_correct_first_image from "../../../../Shared/methods/get_correct_first_image"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"

export default {
  components: {
    entity_selection_modal,
    section_description_preview,
    draggable
  },
  props: {
    can_user_manage_content: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      open_modal: false
    }
  },
  computed: {
    ...mapState(CONTENT_STORE, {
      highlighted_products_data: "highlighted_products"
    }),
    ...mapState(PRODUCTS_STORE, [
      "imported_products",
      "shopify_products"
    ]),
    ...mapState(ADMIN_STORE, [
      "admin_language"
    ]),
    ...mapState(CONFIGURATION_STORE, ["shopify_config"]),
    products_type() {
      return this.shopify_config.should_use_shopify_data ? shopify : imported
    },
    get_products() {
      return this[`${this.products_type}_products`] || []
    },
    get_highlighted_products: {
      get() {
        return this.highlighted_products_data.map(
          highlighted_product => this.get_products.find(({ id }) => highlighted_product === id)
        ).filter(val => val)
      },
      set(updated_positioning) {
        this.update_product_positioning(updated_positioning.map(({ id }) => id))
        this.content_data_updated({ types_updated: ["highlighted_products"] })
      }
    }
  },
  methods: {
    ...mapMutations(CONTENT_STORE, {
      update_highlighted_products: UPDATE_HIGHLIGHTED_PRODUCTS,
      update_product_positioning: UPDATE_HIGHLIGHTED_PRODUCTS_POSITIONING
    }),
    ...mapMutations(ADMIN, {
      content_data_updated: CONTENT_DATA_UPDATED
    }),
    get_correct_first_image,
    get_title(product) {
      return get_correct_translation_value(product, "title", [this.admin_language])
    },
    product_selected(id) {
      this.update_highlighted_products(id)
      this.content_data_updated({ types_updated: ["highlighted_products"] })
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/admin" as *;

  .highlighted-products-admin {
    &__buttons {
      margin: $default-size 0 $default-size;
      text-align: center;
    }

    svg {
      max-width: 400px;
      max-height: 400px;
    }
  }
</style>
