var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"menu-content scroll-content"},[_c('h3',{staticClass:"md-display-1"},[_vm._v(" "+_vm._s(_vm.$translate("menu_content.title"))+" "),_c('video_tutorial',{attrs:{"section":"menu_items"}})],1),_c('hr'),_c('language_switcher',{attrs:{"allow_only_enabled":true,"show_all_languages":false,"selected_language":_vm.selected_language,"bottom_border":true},on:{"change-language":language => _vm.selected_language = language}}),_c('div',{staticClass:"menu-content__items"},[_c('draggable',{attrs:{"group":"header_items"},on:{"start":function($event){_vm.drag=true},"end":function($event){_vm.drag=false}},model:{value:(_vm.get_header_items),callback:function ($$v) {_vm.get_header_items=$$v},expression:"get_header_items"}},[_c('transition-group',_vm._l((_vm.get_header_items),function({ category, sub_items, change_type, parent_category, ...rest },index){return _c('md-card',{key:`header-item-${index}`},[_c('md-card-header',[_c('md-card-actions',[_c('div',[_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$translate("title_translation")))]),_c('md-input',{attrs:{"value":_vm.get_correct_translation_value(rest, 'name', [_vm.selected_language], false),"disabled":change_type === _vm.deleted || !_vm.can_user_manage_content},on:{"change":event => _vm.update_menu_item('name', [index, event.target.value, undefined, _vm.selected_language])}})],1)],1),_c('div',{staticClass:"menu-content__items__buttons"},[(_vm.can_user_manage_content)?_c('md-button',{staticClass:"md-icon-button md-raised",class:{
                    'md-primary': change_type === _vm.deleted,
                    'md-accent': change_type !== _vm.deleted
                  },on:{"click":function($event){return _vm.delete_or_restore([index])}}},[_c('md-icon',[_vm._v(_vm._s(change_type === _vm.deleted ? "restore" : "delete"))]),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v(" "+_vm._s(_vm.$translate(change_type === _vm.deleted ? "restore" : "delete"))+" "+_vm._s(_vm.$translate("menu_content.menu_item"))+" ")])],1):_vm._e(),(!category.includes('?') && _vm.can_user_manage_content && sub_items)?_c('md-speed-dial',{staticClass:"menu-content__small-speed-dial",attrs:{"md-effect":"scale","md-direction":"bottom"},nativeOn:{"click":function($event){return _vm.select_from_entity_clicked(index, category, parent_category)}}},[_c('md-speed-dial-target',{staticClass:"md-plain"},[_c('md-icon',[_vm._v("add")])],1)],1):_vm._e(),(sub_items)?_c('md-card-expand-trigger',[_c('md-button',{staticClass:"md-icon-button"},[_c('md-icon',[_vm._v("keyboard_arrow_down")])],1)],1):_vm._e()],1)])],1),(sub_items)?_c('md-card-expand',[_c('md-card-expand-content',[_c('md-card-content',_vm._l((sub_items),function({ change_type: sub_change_type, ...sub_rest },sub_index){return _c('div',{key:`header-item-sub-${sub_index}-${index}`,staticClass:"menu-content__sub-items"},[_c('div',{staticClass:"menu-content__sub-items__inputs"},[_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$translate("title_translation")))]),_c('md-input',{attrs:{"value":_vm.get_correct_translation_value(sub_rest, 'name', [_vm.selected_language], false),"disabled":sub_change_type === _vm.deleted || !_vm.can_user_manage_content},on:{"change":event => _vm.update_menu_item('name', [index, event.target.value, sub_index, _vm.selected_language])}})],1)],1),(_vm.can_user_manage_content)?_c('md-button',{staticClass:"md-icon-button md-raised menu-content__sub-items__button",class:{
                      'md-primary': sub_change_type === _vm.deleted,
                      'md-accent': sub_change_type !== _vm.deleted
                    },on:{"click":function($event){return _vm.delete_or_restore([index, sub_index])}}},[_c('md-icon',[_vm._v(_vm._s(sub_change_type === _vm.deleted ? "restore" : "delete"))]),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.$translate(sub_change_type === _vm.deleted ? "restore" : "delete"))+" "+_vm._s(_vm.$translate("menu_content.menu_subitem")))])],1):_vm._e()],1)}),0)],1)],1):_vm._e()],1)}),1)],1),(_vm.can_user_manage_content)?_c('md-button',{staticClass:"menu-content__button md-fab md-raised md-primary",nativeOn:{"click":function($event){_vm.open_products_modal = true}}},[_c('md-tooltip',{attrs:{"md-direction":"left"}},[_vm._v(_vm._s(_vm.$translate("menu_content.add_item")))]),_c('md-icon',{attrs:{"data-cy":"add-menu"}},[_vm._v("add")])],1):_vm._e()],1),(_vm.can_user_manage_content)?_c('entity_selection_modal',{attrs:{"modal_title":_vm.$translate('menu_content.modal_title'),"open_modal":_vm.open_products_modal,"index_or_id_of_selected_entity":_vm.index_of_parent_entity,"index_of_selected_header_item":_vm.index_of_menu_item},on:{"entity_selected":_vm.entity_selected,"collection_selected":index => _vm.selected_collection = index,"toggle_modal":_vm.toggle_modal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }