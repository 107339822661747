<template>
  <section class="importnant-message-bar scroll-content">
    <h3 class="md-display-1">{{ $translate("important_message_bar.title") }}</h3>

    <section_description_preview :dense="true">
      <div class="md-body-1">{{ $translate("important_message_bar.message") }}</div>
    </section_description_preview>

    <div class="importnant-message-bar__inputs">
      <md-field>
        <label>{{ $translate("important_message_bar.important_message") }}</label>
        <md-input
          data-cy="important-message-text"
          :disabled="!can_user_manage_content"
          :value="important_message_bar_data || ''"
          @change="event => update_message(event.target.value)"
          :placeholder="$translate('important_message_bar.important_message')"
        />
      </md-field>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { CONTENT_STORE, ADMIN } from "../../../constants/others_constants"
import { UPDATE_IMPORTANT_MESSAGE_BAR } from "../../../stores/Admin/content/constants"
import { CONTENT_DATA_UPDATED } from "../../../stores/Admin/constants"
import section_description_preview from "../section_description_preview"

export default {
  components: {
    section_description_preview
  },
  props: {
    can_user_manage_content: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      open_modal: false
    }
  },
  computed: {
    ...mapState(CONTENT_STORE, {
      important_message_bar_data: "important_message_bar"
    })
  },
  methods: {
    ...mapMutations(CONTENT_STORE, {
      update_important_message_bar: UPDATE_IMPORTANT_MESSAGE_BAR
    }),
    ...mapMutations(ADMIN, {
      content_data_updated: CONTENT_DATA_UPDATED
    }),
    update_message(message) {
      this.update_important_message_bar(message)
      this.content_data_updated({ types_updated: ["important_message_bar"] })
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;

  .importnant-message-bar {
    &__inputs {
      .md-field {
        display: inline-block;
        width: auto;
        min-width: 300px;
      }
    }

    svg {
      max-width: 400px;
      max-height: 400px;
    }
  }
</style>
