<template>
  <section
    class="store-live-preview"
    :class="{
      'store-live-preview--closed': !show_preview,
      'store-live-preview--full-width': is_small_viewport
    }"
  >
    <div class="store-live-preview__editors">
      <slot />
    </div>
    <div
      class="store-live-preview__preview-closer"
      :class="{ 'store-live-preview__preview-closer--show': !show_preview }"
      @click="show_preview = !show_preview"
    >
      <md-tooltip>{{ $translate(show_preview ? 'hide_store_preview' : 'show_store_preview') }}</md-tooltip>
      <md-icon>{{ show_preview ? "arrow_forward_ios" : "arrow_back_ios" }}</md-icon>
    </div>
    <store_preview
      v-show="show_preview"
      :show_about_page="component_type === about_page"
      :component_type="component_type"
    />
  </section>
</template>

<script>
import { mapState } from "vuex"
import { ADMIN_STORE, menu, about_page } from "../../constants/others_constants"
import store_preview from "../utils/store_preview"

export default {
  components: {
    store_preview,
  },
  props: {
    component_type: {
      required: false,
      type: String,
      default: menu,
    },
  },
  data() {
    return {
      show_preview: true,
      about_page,
    }
  },
  computed: {
    ...mapState(ADMIN_STORE, [
      "is_small_viewport"
    ]),
  },
  created() {
    this.show_preview = !this.is_small_viewport
  },
}
</script>


<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;

  .store-live-preview {
    display: grid;
    grid-template-columns: calc(100% - #{$iphone-width + $double-default-size + $half-default-size}) $half-default-size #{$iphone-width + $double-default-size};

    @media (max-width: $tablet--small) {
      grid-template-columns: 0 1px calc(100% - 1px);
    }

    &--closed {
      margin-left: 0 !important;
      grid-template-columns: calc(100% - $half-default-size) $half-default-size 0 !important;

      .store-live-preview__preview-closer {
        transform: translate(-80%, calc(50vh - 50%));
      }
    }

    &--full-screen {
      margin-left: -#{$material-size};
      grid-template-columns: 0 $half-default-size calc(100% - $half-default-size);
    }

    &--full-width {
      grid-template-columns: 0 $half-default-size #{$iphone-width + $double-default-size};

      @media (max-width: $tablet--small) {
        grid-template-columns: 0 1px calc(100% - 1px);
      }
    }

    &__preview-closer {
      display: flex;
      align-content: center;
      transform: translate(-#{$material-button / 2}, calc(50vh - 50%));
      z-index: $z-index--bottom;
      width: $material-button;
      height: $material-button;
      background: $pure-white;
      border-radius: 50%;
      box-shadow: 0 0 20px -5px $shadow-color;
      transition: 200ms ease-in-out;
      cursor: pointer;

      .md-icon {
        min-width: 15px;
        width: 15px;
        transform: translateX(2px);
      }
    }

    &__editors {
      position: relative;
      width: 100%;
      overflow: hidden;
    }
  }

</style>
