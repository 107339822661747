<template>
  <section class="collection-banners scroll-content">
    <h3 class="md-display-1">{{ $translate("collection_banners.title") }}</h3>

    <section_description_preview :dense="true">
      <div class="md-body-1">{{ $translate("collection_banners.message") }}</div>
    </section_description_preview>

    <h3>{{ $translate("collection_banners.section_title") }}:</h3>
    <div class="collection-banners__banners md-layout">
      <md-card
        v-for="({ img, mobile_image, description, change_type, button_text, title = '' }, index) in collection_banners"
        :key="`banner-${index}`"
        class="md-layout-item md-size-33 md-small-size-50 md-xsmall-size-100"
        :class="{ 'collection-banners--deleted': change_type === deleted }"
      >
        <md-card-media>
          <img data-cy="section-banner-image-upload" :src="mobile_image || img" @click="image_update_click(index)">
        </md-card-media>

        <md-card-header>
          <md-field>
            <label>{{ $translate("collection_banners.title_input") }}</label>
            <md-input
              data-cy="collection-banner-text"
              :value="title"
              @change="event => update_banner(['title', index, event.target.value])"
              :disabled="change_type === deleted || !can_user_manage_content"
            />
          </md-field>
          <md-field>
            <label>{{ $translate("collection_banners.button_text") }}</label>
            <md-input
              :value="button_text"
              @change="event => update_banner(['button_text', index, event.target.value])"
              :disabled="change_type === deleted || !can_user_manage_content"
            />
          </md-field>
          <md-field>
            <label>{{ $translate("collection_banners.description") }}</label>
            <md-textarea
              data-cy="collection-banner-description"
              :value="description"
              @change="event => update_banner(['description', index, event.target.value])"
              :disabled="change_type === deleted || !can_user_manage_content"
            />
          </md-field>
          <md-button
            data-cy="collection-banner-delete"
            data-cy-alternate="collection-banner-restore"
            v-if="can_user_manage_content"
            class="md-icon-button md-raised"
            @click="delete_or_restore(index, change_type === deleted ? undefined : deleted)"
            :class="{
              'md-primary': change_type === deleted,
              'md-accent': change_type !== deleted
            }"
          >
            <md-icon>{{ change_type === deleted ? "restore" : "delete"}}</md-icon>
            <md-tooltip md-direction="top">
              {{ $translate(change_type === deleted ? "restore" : "delete") }} {{ $translate("collection_banners.collection_banner") }}
            </md-tooltip>
          </md-button>
        </md-card-header>
      </md-card>

      <md-button
        data-cy="add-collection-banner"
        v-if="can_user_manage_content"
        class="vertical-inline-block md-primary md-raised md-icon-button md-fab"
        @click="open_banner_modal = true"
      >
        <md-icon>add</md-icon>
      </md-button>
    </div>

    <entity_selection_modal
      v-if="(collections.length || products_without_collection.length) && can_user_manage_content"
      :modal_title="$translate('collection_banners.modal_title')"
      :open_modal="open_banner_modal"
      @entity_selected="select_entity"
      @collection_selected="index => selected_collection = index"
      @toggle_modal="val => open_banner_modal = val"
    />

    <md-dialog v-if="can_user_manage_content" :md-active.sync="open_image_modal" class="collection-banners__modal">
      <md-dialog-title>
        {{ $translate("collection_banners.upload_banner") }}
      </md-dialog-title>

      <div v-if="uploading_image">
        <div v-if="uploaded_image.src">
          <img :src="uploaded_image.src">

          <md-button class="md-raised md-accent collection-banners__button" @click="uploaded_image = { src: '', file: '' }">
            {{ $translate("clear_image") }}
          </md-button>
          <md-button class="md-raised md-primary collection-banners__button" @click="save_image">
            {{ $translate("upload_image") }}
          </md-button>
        </div>
        <div v-else class="md-elevation-5 image-upload">
          <input
            data-cy="collection-banner-upload-input"
            type="file"
            id="image-upload"
            accept="image/*"
            @drop="event => add_image(event.target.files[0])"
            @input="event => add_image(event.target.files[0])"
            v-cloak
          >
          <label for="image-upload">
            <md-icon>cloud_upload</md-icon>
            {{ $translate("drag_and_drop_image") }}
          </label>
        </div>
      </div>
    </md-dialog>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import url_enity_encoder from "urlencode"
import { CONTENT_STORE, ADMIN, deleted, PRODUCTS_STORE, CONFIGURATION_STORE, CONTENT } from "../../../constants/others_constants"
import {
  UPDATE_COLLECTION_BANNER,
  DELETE_OR_RESTORE_COLLECTION_BANNER,
  ADD_COLLECTION_BANNER
} from "../../../stores/Admin/content/constants"
import { CONTENT_DATA_UPDATED, UPDATE_IMAGES_FOR_DELETE, UPDATE_LOADER, USER_EDITED_SPECIAL_HOMEPAGES } from "../../../stores/Admin/constants"
import validator from "../../../methods/validations/collection_banners"
import entity_selection_modal from "../entity_selection_modal"
import { collection, product } from "../../../../Shared/constants/other"
import get_curly_brace_content from "../../../../Shared/methods/get_curly_brace_content"
import section_description_preview from "../section_description_preview"
import { image_dimension_names, page_types } from "../../../../../data/other_constants"
import { UPDATE_PAGE_TYPE_TRANSLATION } from "../../../stores/Admin/configuration/constants"
import upload_image from "../../../methods/upload_image"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import get_correct_first_image from "../../../../Shared/methods/get_correct_first_image"

export default {
  components: {
    entity_selection_modal,
    section_description_preview
  },
  props: {
    can_user_manage_content: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      open_image_modal: false,
      open_banner_modal: false,
      uploading_image: false,
      selected_banner: null,
      selected_collection: 0,
      uploaded_image: { src: "", file: "" },
      deleted
    }
  },
  computed: {
    ...mapState(CONTENT_STORE, ["banners"]),
    ...mapState(CONFIGURATION_STORE, ["project_config"]),
    ...mapState(PRODUCTS_STORE, [
      "imported_collections",
      "imported_products",
      "shopify_collections",
      "shopify_products"
    ]),
    collection_banners() {
      return (this.banners || {}).collection_banners || []
    },
    products_without_collection() {
      return [
        ...(this.imported_products || []),
        ...(this.shopify_products || [])
      ].filter(({ collections }) => !collections || !collections.length)
    },
    collections() {
      return [
        ...(this.imported_collections || []),
        ...(this.shopify_collections || []),
        ...(
          this.products_without_collection.length ?
          [{
            title: this.$translate("products.parent_collections.without_collection"),
            products: this.products_without_collection
          }] : []
        )
      ]
    },
    are_there_any_collection_banners() {
      return this.collection_banners.filter(({ change_type }) => change_type !== deleted).length
    }
  },
  methods: {
    ...mapMutations(CONTENT_STORE, {
      update_collection_banner: UPDATE_COLLECTION_BANNER,
      delete_or_restore_collection_banner: DELETE_OR_RESTORE_COLLECTION_BANNER,
      add_collection_banner: ADD_COLLECTION_BANNER
    }),
    ...mapMutations(ADMIN, {
      content_data_updated: CONTENT_DATA_UPDATED,
      user_edited_special_homepages: USER_EDITED_SPECIAL_HOMEPAGES,
      update_loader: UPDATE_LOADER,
      image_delete_toggle: UPDATE_IMAGES_FOR_DELETE
    }),
    ...mapMutations(CONFIGURATION_STORE, {
      update_page_type: UPDATE_PAGE_TYPE_TRANSLATION,
    }),
    update_banner(payload) {
      this.update_collection_banner(payload)
      this.content_data_updated({ types_updated: ["banners"] })
      this.validate()
    },
    delete_or_restore(index, change_type) {
      if (this.collection_banners[index]?.img.includes("banners/collection_banner")) this.image_delete_toggle({
        images: [
          this.collection_banners[index].img,
          this.collection_banners[index].mobile_image
        ],
        store_type: CONTENT
      })

      this.delete_or_restore_collection_banner([index, change_type])
      this.content_data_updated({ types_updated: ["banners"] })
      this.validate()
    },
    add_banner(payload = []) {
      this.add_collection_banner(payload)
      this.content_data_updated({ types_updated: ["banners"] })
      this.validate()
    },
    add_image(value) {
      try {
        const reader = new FileReader()

        reader.addEventListener("load", () => {
          this.uploaded_image = {
            src: reader.result,
            file: value,
          }
        }, false)

        reader.readAsDataURL(value)
      } catch (error) {
        console.log(error)
      }
    },
    async save_image() {
      this.update_loader(true)

      // Only delete image if specifically uploaded to be a collection banner
      if (this.collection_banners[this.selected_banner]?.img.includes("banners/collection_banner")) this.image_delete_toggle({
        images: [
          this.collection_banners[this.selected_banner].img,
          this.collection_banners[this.selected_banner].mobile_image
        ],
        store_type: CONTENT
      })

      const image_sources = await upload_image(
        this.uploaded_image,
        `banners/collection_banner/${this.uploaded_image.file.name}`,
        false,
        true,
        false
      )

      if (!image_sources) return this.update_loader(false)

      this.update_banner(["img", this.selected_banner, image_sources[image_dimension_names.tablet]])
      this.update_banner(["mobile_image", this.selected_banner, image_sources[image_dimension_names.mobile]])
      this.uploading_image = false
      this.open_image_modal = false
      this.uploaded_image = { src: "", file: "" }
      this.update_loader(false)
    },
    image_update_click(ind) {
      this.uploading_image = true
      this.open_image_modal = true
      this.selected_banner = ind
    },
    select_entity(collection_data, product_data, link_to_microsite, selecting_collection, selecting_parent_collection) {
      const parent_collection = get_curly_brace_content(collection_data.body_html)
      const category = selecting_parent_collection ?
        parent_collection[1] :
        parent_collection ? `${parent_collection[1]}/${collection_data.handle}` : collection_data.handle
      const sub_category = (selecting_parent_collection || selecting_collection) ?
        undefined : (product_data.buffer_id || product_data.id)
      const button_text = selecting_parent_collection ?
        url_enity_encoder.decode(parent_collection[1]) :
        get_correct_translation_value(selecting_collection ? collection_data : product_data, "title")
      const image = product_data ? get_correct_first_image(product_data, image_dimension_names.tablet) : collection_data?.image
      const mobile_image = product_data ? get_correct_first_image(product_data, image_dimension_names.mobile) : collection_data?.image
      const route = link_to_microsite ? 
        `/?${selecting_collection ? collection : product}=${
          selecting_collection ? collection_data.handle : (product_data.buffer_id || product_data.id)
        }`
        : undefined

      this.add_banner([
        category,
        sub_category,
        image,
        mobile_image,
        route,
        button_text
      ])

      if (link_to_microsite) {
        this.update_page_type([
          product_data ? page_types.single_product_homepages : page_types.couple_products_homepages,
          product_data ? (product_data.buffer_id || product_data.id) : collection_data.handle,
        ])
        this.user_edited_special_homepages(true)
      }

      this.open_banner_modal = false
      this.selected_collection = 0
    },
    ...validator
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/admin_image_upload.scss";

  .collection-banners {
    &--deleted {
      background-color: rgba($material-red, 0.5) !important;
    }

    .md-speed-dial {
      height: 160px;
    }

    &__modal {
      text-align: center;
      padding-bottom: $default-size;

      img {
        width: 100%;
        max-height: 400px;
        object-fit: contain;
      }
    }

    &__banners {
      .md-card {
        margin-bottom: $default-size;
      }

      img {
        display: block;
        max-height: 200px;
        max-width: 300px;
        object-fit: contain;
        object-position: center;
        margin: auto;
        cursor: pointer;
      }
    }

    .md-textarea {
      min-height: 200px !important;
    }
  }
</style>
