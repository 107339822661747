<template>
  <section v-if="data_loaded" class="about-page" :class="{ 'about-page--admin': admin_imported && show_mobile }">
    <img
      v-if="(get_about_page_content.top_banner || {}).src"
      class="about-page__banner"
      :src="get_about_page_content.top_banner[is_mobile_device ? 'thumb_src' : 'src']"
      alt="top banner image"
    >

    <p
      class="about-page__title"
      :class="{
        'about-page__title--negative-margin': (get_about_page_content.top_banner || {}).src
      }"
    >
      {{ texts.title || "About" }}
    </p>

    <br>

    <p v-if="texts.quote" class="about-page__quote">„{{ texts.quote }}“</p>

    <div
      v-if="top_paragraph"
      class="about-page__top-paragraph"
    >
      <div v-if="split_string_into_two(top_paragraph).length === 1">{{ top_paragraph }}</div>
      <p
        v-else
        v-for="(paragraph, ind) in split_string_into_two(top_paragraph)"
        :key="`about-top-paragraph-${ind}`"
      >
        {{ paragraph }}
      </p>
    </div>

    <div
      v-if="get_about_page_content.tile_pairs && get_about_page_content.tile_pairs.length"
      class="about-page__tile-pairs"
    >
      <div
        v-for="(tiles, index) in get_about_page_content.tile_pairs"
        :key="`tile-pairs-${index}`"
        class="about-page__tile-pair"
      >
        <div
          v-for="(tile, ind) in tiles"
          :key="`tile-${index}-${ind}`"
          class="about-page__tile"
          :class="{
            'about-page__tile--non-image': !tile || !tile.src,
            'about-page__tile--text-right': ind === 0
          }"
        >
          <img
            v-if="tile && tile.src"
            :src="tile.src[!user_is_using_fast_connection && tile.src.thumb_url ? 'thumb_url' : 'image_url']"
            alt="tile image"
          >
          <p v-else>
            {{ tile ? tile[language] : "" }}
          </p>
        </div>
      </div>
    </div>

    <img
      v-if="(get_about_page_content.bottom_banner || {}).src"
      class="about-page__banner about-page__banner--bottom"
      :src="get_about_page_content.bottom_banner[!user_is_using_fast_connection && get_about_page_content.bottom_banner.thumb_src ? 'thumb_src' : 'src']"
      alt="bottom banner image"
    >

    <div
      v-if="
        get_about_page_content.bottom_paragraphs &&
        get_about_page_content.bottom_paragraphs.length &&
        get_about_page_content.bottom_paragraphs.every(paragraph => paragraph[language] && paragraph[language].title)
      "
      class="about-page__bottom-paragraphs"
      :class="{ 'about-page__bottom-paragraphs--negative-margin': (get_about_page_content.bottom_banner || {}).src }"
    >
      <div
        v-for="(paragraph, ind) in get_about_page_content.bottom_paragraphs"
        :key="`bottom-paragraph-${ind}`"
        class="about-page__bottom-paragraph"
      >
        <p>
          {{ paragraph[language].title }}
        </p>
        <div>
          {{ paragraph[language].message }}
        </div>
      </div>
    </div>
  </section>
  <div v-else>
    <div class="about-page__banner element-loading" />
    <div class="about-page__top-paragraph">
      <p class="element-loading" />
      <p class="element-loading" />
    </div>

    <div class="about-page__tile-pairs">
      <div class="about-page__tile-pair">
        <div class="about-page__tile about-page__tile--text-right element-loading" />
        <div class="about-page__tile element-loading" />
      </div>
    </div>

    <div class="about-page__banner about-page__banner--bottom element-loading" />

    <div class="about-page__bottom-paragraphs">
      <div class="about-page__bottom-paragraph element-loading" />
      <div class="about-page__bottom-paragraph element-loading" />
      <div class="about-page__bottom-paragraph element-loading" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import { CLIENT_STORE } from "../../constants/other"
import { CONTENT_STORE } from "../../../Admin/constants/others_constants"
import { SHARED_STORE } from "../../../Shared/constants/other"
import { fetch_about_page } from "../../constants/endpoints"
import { UPDATE_ABOUT_PAGE } from "../../stores/Client/constants"

export default {
  props: {
    admin_imported: {
      type: Boolean,
      required: false,
      default: false,
    },
    admin_language: {
      type: String,
      required: false,
      default: "",
    },
    show_mobile: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return { data_loaded: false }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_device",
    ]),
    ...mapState(CLIENT_STORE, [
      "about_page_content",
      "selected_language",
      "user_is_using_fast_connection"
    ]),
    ...mapState(CONTENT_STORE, {
      admin_about_page_content: "about_page_content"
    }),
    get_about_page_content() {
      return this.admin_imported ? this.admin_about_page_content : this.about_page_content || {}
    },
    language() {
      return this.admin_imported ?
        this.admin_language :
        this.get_about_page_content.texts[this.selected_language] ? this.selected_language : Object.keys(this.get_about_page_content.texts)[0]
    },
    texts() {
      return (this.get_about_page_content.texts[this.language] || {})
    },
    top_paragraph() {
      return this.get_about_page_content.top_paragraph ?
        (this.get_about_page_content.top_paragraph[this.language] || "") : ""
    }
  },
  async mounted() {
    if (!this.admin_imported && !this.about_page_content) {
      const { data } = await fetch_about_page()
      this.update_about_page(data)
    }

    this.data_loaded = true
  },
  methods: {
    ...mapMutations(CLIENT_STORE, {
      update_about_page: UPDATE_ABOUT_PAGE
    }),
    split_string_into_two(str) {
      let middle = Math.floor(str.length / 2);

      if (middle > 150) {
        const before = str.lastIndexOf(' ', middle);
        const after = str.indexOf(' ', middle + 1);

        if (before == -1 || (after != -1 && middle - before >= after - middle)) {
          middle = after;
        } else middle = before;

        const first = str.substr(0, middle);
        const second = str.substr(middle + 1);

        return [first, second]
      }

      return [str]
    }
  }
}
</script>

<style lang="scss">
@use "sass:math";
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/element_loading";

$class: ".about-page";

#{$class} {
  text-align: center;
  color: var(--font_heavy);

  &--admin {
    padding: 20px 10px;

    #{$class} {
      &__title {
        padding: 30px 20px 30px;
        margin: 0 0 100px;
        font-size: 35px;

        &--negative-margin {
          min-width: 250px;
          max-width: 90%;
          margin-top: -100%;
        }
      }

      &__quote {
        margin: 0 0 50px;
        font-size: 25px;
      }

      &__top-paragraph {
        font-size: 13px;
      }

      &__tile {
        width: 100%;
        padding: 0 0 100% 0;
        margin-right: 0;

        &--non-image {
          padding: 0 0 0 0 !important;
          display: block;
          height: auto;

          p {
            position: relative;
            top: 0;
            padding: 20px;
            transform: unset;
          }
        }

        &--text-right p {
          right: auto;
        }

        p {
          font-size: 13px;
        }

        &:last-child {
          display: block;
          width: 100%;
          padding: 0 0 100% 0;
          margin-top: 20px;
          margin-left: 0;
        }
      }

      &__bottom-paragraph {
        margin: 0 0 30px;

        p {
          font-size: 16px;
        }

        div {
          font-size: 13px;
        }

        &s {
          margin-top: 20px;
          grid-row-gap: 20px;
          grid-template-columns: 1fr;

          &--negative-margin {
            margin-top: 20px;
          }
        }
      }
    }
  }

  @media (max-width: $tablet) {
    padding-top: 20px;
  }

  $divider-line-width: 100px;
  $shadow: 0 30px 70px -20px $shadow-color, 0 53px 50px -50px $shadow-color; 

  &__title {
    position: relative;
    display: inline-block;
    padding: 40px 20px 40px;
    margin: 0 0 150px;
    width: 100%;
    font-size: 50px;
    font-family: 'Abril Fatface', sans-serif;
    background: var(--body_color);
    box-sizing: border-box;

    &--negative-margin {
      min-width: 350px;
      max-width: 80%;
      width: auto;
      margin-top: -100%;
      box-shadow: 0 -33px 45px -25px $shadow-color;
    }

    @media (max-width: $tablet) {
      padding: 30px 20px 30px;
      margin: 0 0 100px;
      font-size: 35px;

      &--negative-margin {
        min-width: 250px;
        max-width: 90%;
        margin-top: -100%;
      }
    }

    &::after {
      position: absolute;
      width: $divider-line-width;
      height: 3px;
      bottom: -38.5px;
      left: calc(50% - #{math.div($divider-line-width, 2)});
      background-color: var(--font_heavy);
      content: "";
    }
  }

  &__quote {
    display: inline-block;
    margin: 0 0 150px;
    font-size: 35px;
    font-family: 'Abril Fatface', sans-serif;

    @media (max-width: $tablet) {
      margin: 0 0 50px;
      font-size: 25px;
    }
  }

  &__banner {
    width: 100%;
    margin: 0 0 20px;
    object-fit: cover;
    object-position: center;
    max-height: 300px;
    border-radius: 3px;

    &.element-loading {
      height: 200px;
    }

    &--bottom {
      margin: 20px 0;
    }
  }

  &__top-paragraph {
    position: relative;
    margin-bottom: 50px;

    @media (max-width: $tablet) {
      font-size: 13px;
    }

    p {
      display: inline-block;
      width: 50%;
      padding: 10px 10px 10px 5px;
      margin: 0;
      box-sizing: border-box;
      text-align: left;
      vertical-align: top;

      &.element-loading {
        height: 50px;
        width: calc(50% - 3px);
      }

      &:last-child {
        padding: 10px 5px 10px 10px;
      }
    }

    div {
      padding: 10px 20px;
      text-align: center;
    }

    &::after {
      position: absolute;
      width: $divider-line-width;
      height: 3px;
      bottom: -50px;
      left: calc(50% - #{math.div($divider-line-width, 2)});
      background-color: var(--font_heavy);
      content: "";
    }
  }

  &__tile {
    position: relative;
    display: inline-block;
    width: calc(50% - 20px);
    height: 0;
    padding: 0 0 calc(50% - 20px) 0;
    margin-right: 20px;
    background-color: var(--background_heavy);
    color: var(--background_light);
    box-sizing: border-box;
    border-radius: 3px;

    &.element-loading {
      width: calc(50% - 20px - 3px);

      @media (max-width: $tablet--small) {
        width: 100%;
      }
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
      box-shadow: $shadow;
      border-radius: 3px;
    }

    &--text-right p {
      right: 0;

      @media (max-width: $tablet--small) {
        right: auto;
      }
    }
    
    p {
      position: absolute;
      max-height: 100%;
      margin: 0;
      top: 50%;
      padding: 0 20px;
      transform: translate(0,-50%);
      overflow-y: auto;
      line-height: 25px;

      @media (max-width: $tablet) {
        font-size: 13px;
      }
    }

    &:last-child {
      margin-right: 0;
      margin-left: 20px;

      @media (max-width: $tablet--small) {
        display: block;
        width: 100%;
        padding: 0 0 100% 0;
        margin-top: 20px;
        margin-left: 0;
      }
    }

    &-pair {
      margin: 0 0 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (max-width: $tablet--small) {
      width: 100%;
      padding: 0 0 100% 0;
      margin-right: 0;

      &--non-image {
        padding: 0 0 0 0 !important;
        display: block;
        height: auto;

        p {
          position: relative;
          top: 0;
          padding: 20px;
          transform: unset;
        }
      }
    }

    &-pairs {
      position: relative;
      padding: 20px 0;
      text-align: left;

      &::before {
        position: absolute;
        width: 3px;
        height: 100%;
        top: 0;
        left: calc(50% - 1.5px);
        background-color: var(--font_heavy);
        content: "";
      }

      &::after {
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        background-color: var(--font_heavy);
        content: "";
      }
    }
  }

  &__bottom-paragraph {
    background-color: var(--body_color);
    padding: 20px 0;
    box-shadow: 0px -22px 45px -24px rgba(0, 0, 0, 0.3);
    border-radius: 3px;

    p {
      display: inline-block;
      padding: 0 0 10px;
      margin: 0 0 20px;
      border-bottom: 2px solid var(--font_heavy);
      font-size: 20px;

      @media (max-width: $tablet) {
        font-size: 16px;
      }
    }
    div {
      padding: 0 20px;

      @media (max-width: $tablet) {
        font-size: 13px;
      }
    }

    @media (max-width: $tablet--small) {
      margin: 0 0 30px;
    }

    &s {
      display: grid;
      margin-top: 40px;
      grid-column-gap: 20px;
      grid-template-columns: repeat(3, 1fr);

      &--negative-margin {
        margin-top: -80px;
      }

      @media (max-width: $tablet--small) {
        margin-top: 20px;

        &--negative-margin {
          margin-top: -80px;
        }
      }
      
      @media (max-width: $tablet--small) {
        grid-row-gap: 20px;
        grid-template-columns: 1fr;

        &--negative-margin {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
