<template>
  <section class="store-preview">
    <div
      class="store-preview__content"
      :class="{ 'store-preview__full-screen': !show_mobile }"
    >
      <div class="store-preview__content-top-bar">
        <span>{{ current_time }}</span>
        <div>
          <md-icon>signal_cellular_alt</md-icon>
          <md-icon>battery_5_bar</md-icon>
        </div>
      </div>
      <span class="store-preview__content-speaker">Speaker</span>
      <span class="store-preview__content-camera">Camera</span>
      <div class="store-preview__content-inner" ref="preview_content">
        <div
          v-if="(
            show_about_page &&
            selected_about_page_language &&
            about_page_content.texts
          )"
          class="store-preview__homepage-content"
        >
          <About_page
            :admin_imported="true"
            :admin_language="selected_about_page_language"
            :show_mobile="show_mobile"
          />
        </div>
        <div v-else-if="!show_about_page" class="store-preview__body">
          <div>
            <Menu
              :liked="0"
              :in_cart="0"
              :admin_imported="true"
              :show_mobile="show_mobile"
              :ref="menu"
              :guide_data="guide_data"
              :inside_user_guide="Boolean(guide_data)"
              @handle_click="handle_click(menu)"
            />
            <Important_message_bar
              :admin_imported="true"
              :ref="important_message_bar"
              @click.native="handle_click(important_message_bar)"
            />
            <div class="store-preview__homepage-content">
              <Special_message_banner
                :admin_imported="true"
                :show_mobile="show_mobile"
                :ref="special_message_banner"
                :inside_user_guide="Boolean(guide_data)"
                @click.native="handle_click(special_message_banner)"
              />
              <div v-if="!get_popular_products || get_popular_products.length">
                <h3 class="title title__normal">
                  {{ (translations[language] || {}).random_items }}
                </h3>
                <div
                  class="store-preview__popular-products"
                  :ref="popular_products_name"
                >
                  <div
                    v-if="!get_popular_products"
                    class="store-preview__popular-products-message"
                  >
                    <p class="md-title">
                      {{
                        $translate("popular_products.demo_message_product_slider")
                      }}
                    </p>
                  </div>
                  <product_slider
                    :products="get_popular_products || base_preview_products"
                    :load_right_away="true"
                    :admin_imported="true"
                    :show_mobile="show_mobile"
                    :showing_user_guide="Boolean(guide_data)"
                    @handle_click="handle_click(popular_products_name)"
                  />
                </div>
              </div>
              <Collection_banners
                :admin_imported="true"
                :ref="collection_banner"
                :show_mobile="show_mobile"
                :guide_data="guide_data && guide_data.collection_banners"
                @handle_click="handle_click(collection_banner)"
                @click.native="handle_click(collection_banner)"
              />
              <Story_banner
                :admin_imported="true"
                :ref="story_banner"
                :show_mobile="show_mobile"
                @click.native="handle_click(story_banner)"
              />
              <Highlighted_products
                :imported_products="get_all_products"
                :admin_imported="true"
                :ref="highlighted_products_name"
                :show_mobile="show_mobile"
                :guide_data="guide_data && guide_data.highlighted_products"
                @handle_click="handle_click(highlighted_products_name)"
                @click.native="handle_click(highlighted_products_name)"
              />
            </div>
          </div>
          <Footer
            :admin_imported="true"
            :ref="footer"
            :show_mobile="show_mobile"
            :guide_data="guide_data && guide_data.uploaded_logo"
          />
        </div>
      </div>
    </div>
    <transition name="store-preview-message">
      <div v-if="show_message" class="store-preview__message">
        {{ $translate("demo_store_preview_message") }}
      </div>
    </transition>

    <!-- Disallow full screen view until further notice -->
    <!-- <md-button
      v-if="!is_small_viewport && !guide_preview"
      class="md-raised md-primary store-preview__full-screen-button"
      @click="switch_view"
    >
      {{
        show_mobile
          ? $translate("full_screen_preview")
          : $translate("mobile_preview")
      }}
    </md-button> -->
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Collection_banners from "../../../Client/components/utils/home/collection_banners";
import Highlighted_products from "../../../Client/components/utils/home/highlighted_products";
import Story_banner from "../../../Client/components/utils/home/story_banner";
import Important_message_bar from "../../../Client/components/utils/important_message_bar";
import Special_message_banner from "../../../Client/components/utils/special_message_banner";
import Footer from "../../../Client/components/base/Footer";
import Menu from "../../../Client/components/base/Menu";
import About_page from "../../../Client/components/default-pages/About_page";
import product_slider from "../../../Shared/components/utils/product_slider";

import { default_language } from "../../../../../functions/constans/other";
import {
  CONFIGURATION_STORE,
  CONTENT_STORE,
  PRODUCTS_STORE,
  menu,
  footer,
  collection_banner,
  special_message_banner,
  popular_products,
  highlighted_products,
  story_banner,
  important_message_bar,
  ADMIN_STORE,
} from "../../constants/others_constants";
import { SHARED_STORE } from "../../../Shared/constants/other";
import { UPDATE_MOBILE_VIEW } from "../../../Shared/stores/Shared/constants";
import { CLIENT_STORE } from "../../../Client/constants/other";
import empty_product_object from "../../constants/empty_objects/empty_product_object";
import {
  UPDATE_SELECTED_LANGUAGE,
  UPDATE_TRANSLATIONS,
} from "../../../Client/stores/Client/constants";
import { event_hub } from "../../../../main";
import { UPDATE_ACTIVE_SIDE_MENU_ICON } from "../../stores/Admin/constants";

export default {
  components: {
    Collection_banners,
    Highlighted_products,
    Story_banner,
    Important_message_bar,
    Special_message_banner,
    Footer,
    Menu,
    product_slider,
    About_page,
  },
  props: {
    show_about_page: {
      type: Boolean,
      required: true,
    },
    component_type: {
      type: String,
      required: false,
      default: "",
    },
    guide_preview: {
      type: Boolean,
      required: false,
      default: false,
    },
    guide_data: {
      type: Object | null,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selected_about_page_language: "",
      show_message: false,
      menu,
      highlighted_products_name: highlighted_products,
      popular_products_name: popular_products,
      show_mobile: true,
      collection_banner,
      footer,
      special_message_banner,
      story_banner,
      important_message_bar,
      base_preview_products: [],
      current_time: ""
    };
  },
  computed: {
    ...mapState(CONTENT_STORE, [
      "popular_products",
      "highlighted_products",
      "about_page_content",
    ]),
    ...mapState(PRODUCTS_STORE, ["imported_products", "shopify_products"]),
    ...mapState(CONFIGURATION_STORE, ["translations"]),
    ...mapState(CLIENT_STORE, ["selected_language"]),
    ...mapState(ADMIN_STORE, ["is_small_viewport"]),
    language() {
      if (this.selected_language) return this.selected_language;

      return this.translations[default_language]
        ? default_language
        : Object.keys(this.translations)[0];
    },
    get_all_products() {
      return [
        ...(this.imported_products || []),
        ...(this.shopify_products || []),
      ];
    },
    get_popular_products() {
      if (this.guide_data) return this.guide_data.popular_products;

      const popular_products = this.get_all_products.filter(
        ({ id, buffer_id }) => this.popular_products.includes(buffer_id || id)
      );

      return popular_products;
    },
  },
  watch: {
    component_type(new_val, old_val) {
      if (new_val !== old_val && new_val)
        this.$nextTick(() => this.scroll_to_component());
    },
  },
  created() {
    this.update_mobile_view(true);
    if (!event_hub._events.change_store_language)
      event_hub.$on(
        "change_store_language",
        (language) => (this.selected_about_page_language = language)
      );
    this.upload_translations(this.translations);
    this.update_selected_language(Object.keys(this.translations)[0]);
  },
  mounted() {
    const empty_product = empty_product_object(
      null,
      null,
      true,
      [default_language],
      this.translations
    );

    this.base_preview_products = [
      empty_product,
      empty_product,
      empty_product,
      empty_product,
    ];
    this.scroll_to_component();

    if (!event_hub._events.toggle_mobile_menu)
      event_hub.$on("toggle_mobile_menu", this.toggle_mobile_menu);

    this.set_current_formatted_time()
    this.time_interval = setInterval(() => {
      this.set_current_formatted_time()
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.time_interval);
  },
  methods: {
    ...mapMutations(SHARED_STORE, {
      update_mobile_view: UPDATE_MOBILE_VIEW,
    }),
    ...mapMutations(CLIENT_STORE, {
      upload_translations: UPDATE_TRANSLATIONS,
      update_selected_language: UPDATE_SELECTED_LANGUAGE,
    }),
    ...mapMutations(ADMIN_STORE, {
      update_active_icon: UPDATE_ACTIVE_SIDE_MENU_ICON,
    }),
    set_current_formatted_time() {
      const date = new Date();
      const hours = date.getHours();
      const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

      this.current_time = `${hours}:${minutes}`;
    },
    handle_click(section) {
      if (this.guide_preview) return;

      this.$router.push(`/admin/content/${section}`);
      this.update_active_icon(section);
    },
    switch_view() {
      this.show_mobile = !this.show_mobile;
      this.update_mobile_view(this.show_mobile);
      this.$emit("switch_view", this.show_mobile);
    },
    toggle_mobile_menu() {
      if (document.querySelector("#mobile-menu-toggle"))
        document.querySelector("#mobile-menu-toggle").click();
    },
    toggle_mobile_menu() {
      if (
        document.querySelector("#mobile-menu-toggle")
      ) document.querySelector("#mobile-menu-toggle").click()
    },
    scroll_to_component() {
      if (!this.component_type) return;

      const element = this.$refs[this.component_type]
        ? this.$refs[this.component_type].$el || this.$refs[this.component_type]
        : null;

      // Check that element not a comment
      if (element && element.nodeType === Node.ELEMENT_NODE) {
        element.classList.add("store-preview--blink");
        this.$refs.preview_content.scrollTop = element.offsetTop - 70; //menu height + margin

        setTimeout(
          () => element.classList.remove("store-preview--blink"),
          3000
        );
      } else if (this.$refs.preview_content)
        this.$refs.preview_content.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../../../../styles/_admin-constants" as *;
@use "../../../../styles/_global-constants" as *;

.store-preview {
  position: relative;
  text-align: left;

  &__message {
    display: flex;
    position: absolute;
    top: 52px;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    z-index: 5;
    padding: 20px;
    color: $pure-black;
    text-align: center;
    background-color: rgba($pure-white, 0.8);
  }

  p {
    padding-left: $half-default-size;
  }

  &__popular-products {
    position: relative;

    &-message {
      position: absolute;
      top: 0;
      left: 5px;
      right: 5px;
      border-radius: $border-radius;
      height: 100%;
      background: $transparency-white-color;
      z-index: 1;

      p {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        margin: 0;
      }
    }
  }

  &__full-screen-button {
    position: absolute;
    bottom: $default-size;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
  }

  &__content {
    $top-content-height: 35px;

    position: relative;
    width: $iphone-width;
    margin: $double-default-size $default-size;
    // Height is 100vh - top bar height - margin - quick links height
    height: calc(100vh - #{$admin-top-bar-height + $double-default-size + $material-button-dense});
    overflow: hidden;
    padding-top: 0;
    border-radius: 40px;
    box-shadow:
      0px 0px 0px 11px #1c1c1c,
      0px 0px 0px 13px #191919,
      0px 0px 0px 20px #111;
    
    @media (max-width: $material-medium-breakpoint) {
      height: calc(100vh - #{$admin-top-bar-height--mobile + $double-default-size + $material-button-dense});
    }

    @media (max-width: $tablet--small) {
      height: calc(100vh - #{$admin-top-bar-height--mobile + $default-size + $material-button-dense});
      margin: $default-size 0;
      transform: scale(0.94);
      width: 100%;
      box-shadow: 0px 0px 0px 11px #1c1c1c, 0px 0px 0px 13px #191919
    }

    &-top-bar {
      display: flex;
      position: relative;
      align-items: center;
      height: $top-content-height;
      background-color: var(--background_heavy);
      padding: $half-default-size 26px 0;
      font-weight: 500;
      color: var(--item_name_color);
      font-size: $font-size--small;

      div {
        position: absolute;
        margin: 0;
        top: 11px;
        right: 21px;
        font-weight: normal;

        i {
          color: var(--item_name_color) !important;
          font-size: 17px !important;
          height: 16px;

          &:last-child {
            transform: translateY(1px) rotate(90deg);
            font-size: 22px !important;
          }
        }
      }
    }

    &-inner {
      position: relative;
      width: 100%;
      height: calc(100vh - #{$admin-top-bar-height + $double-default-size + $top-content-height});
      overflow-x: hidden;
      overflow-y: auto;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }

    // frontal camera/speaker frame
    &:before {
      top: 0;
      width: 56%;
      height: 30px;
      background-color: #1c1c1c;
      border-radius: 0px 0px 40px 40px;
    }

    // speaker
    &-speaker {
      position: absolute;
      display: block;
      color: transparent;
      top: 0;
      left: 50%;
      transform: translate(-50%, 6px);
      height: 8px;
      width: 15%;
      background-color: #101010;
      border-radius: 8px;
      box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
      z-index: 10;
    }

    // camera
    &-camera {
      position: absolute;
      display: block;
      color: transparent;
      left: 10%;
      top: 0;
      transform: translate(180px, 4px);
      width: 12px;
      height: 12px;
      background-color: #101010;
      border-radius: 12px;
      box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
      z-index: 10;

      @media (max-width: $tablet--small) {
        display: none;
      }

      &:after {
        content: "";
        position: absolute;
        background-color: #2d4d76;
        width: 6px;
        height: 6px;
        top: 2px;
        left: 2px;
        top: 3px;
        left: 3px;
        display: block;
        border-radius: 4px;
        box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
        z-index: 10;
      }
    }
  }

  &__homepage-content {
    padding: 0;
    margin: 0 auto;

  }

  &__body {
    cursor: pointer;
  }

  .menu {
    position: sticky !important;
    top: 0;
    left: 0;

    @media (max-width: $tablet--small) {
      top: -2px !important;
    }

    @media (max-width: $mobile) {
      img {
        width: calc(100% - 5px);
        height: calc(100% - 5px);
      }
    }
  }

  .title {
    margin: $default-size 0;
  }

  .md-headline {
    margin: 0 0 $default-size;
  }

  &-message-enter-active,
  &-message-leave-active {
    transition: 0.2s;
  }
  &-message-enter,
  &-message-leave-to {
    opacity: 0;
  }
}
</style>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/_admin-constants" as *;

.store-preview {
  &__full-screen .store-preview__homepage-content {
    max-width: $maximum-width;
    padding: 0 $default-size;
    margin: 0 auto $double-default-size;
  }

  &--blink {
    transition: 0.3s linear;
    animation: blink-component 3s linear;

    @keyframes blink-component {
      0% {
        filter: drop-shadow(0 0 15px $blue);
      }
      20% {
        filter: drop-shadow(0 0 15px transparent);
      }
      40% {
        filter: drop-shadow(0 0 15px $blue);
      }
      60% {
        filter: drop-shadow(0 0 15px transparent);
      }
      80% {
        filter: drop-shadow(0 0 15px $blue);
      }
      100% {
        filter: drop-shadow(0 0 15px transparent);
      }
    }
  }

  @media (max-width: $tablet--small) {
    .VueCarousel-inner {
      visibility: visible !important;

      .VueCarousel-slide {
        width: 100%;
        min-width: 100%;
      }
    }
  }
}
</style>
