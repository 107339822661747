import validation_imports from "./validation_imports"
import transform_error_list from "../transform_errors_list"
import { MISSING_NAME } from "../../constants/validation/error_types"
import { CONTENT_STORE, deleted } from "../../constants/others_constants"

export default {
  ...validation_imports(CONTENT_STORE),
  async validate() {
    // Clear error list and reset validator
    this.clear_errors("collection-banners")

    // Defining validator without reactivity
    this.validator = true

    // Sub category - Checks if sub category of any menu item is empty
    if (this.banners.collection_banners.some(({ sub_category, direct_route }) => sub_category === "" && direct_route === "")) this.transforming_errors(
      await MISSING_NAME(this.$translate("collection_banners.sub_category"), "collection-banners", this.$translate("value"))
    )
    // Category - Checks if category of any menu item is empty
    if (this.banners.collection_banners.some(({ category }) => category === "")) this.transforming_errors(
      await MISSING_NAME(this.$translate("collection_banners.category"), "collection-banners", this.$translate("value"))
    )
    // Direct route - Checks if direct route of any menu item is empty
    if (this.banners.collection_banners.some(({ direct_route }) => direct_route !== undefined && !direct_route)) this.transforming_errors(
      await MISSING_NAME(this.$translate("collection_banners.direct_route"), "collection-banners", this.$translate("value"))
    )
    // Description - Checks if description of any menu item is empty
    if (this.banners.collection_banners.some(({ description, change_type }) => !description && change_type !== deleted)) this.transforming_errors(
      await MISSING_NAME(this.$translate("collection_banners.description"), "collection-banners", this.$translate("value"))
    )
  
    return this.validator
  },
  transforming_errors(error, remove) {
    this.update_errors(transform_error_list(this.get_error_list(), error, remove, "collection-banners"))
    this.validator = false
  }
}
