<template>
  <div class="product-slider">
    <carousel 
      :autoplay="false"
      :paginationEnabled="true"
      :navigationEnabled="false"
      :perPageCustom="decide_products_per_slide"
      navigationNextLabel=" "
      navigationPrevLabel=" "
    >
      <slide 
        v-for="(product, index) in get_products"
        :key="`${product.id}-${index}-slide`"
        class="product-slider__slide"
        :class="{ 'product-slider__slide--admin': admin_imported && show_mobile }"
      >
        <div v-if="loading || !product.id" class="product-slider__loader">
          <div class="product-slider__background"><div class="element-loading product-slider__background-image" /></div>
          <p class="element-loading"/>
          <p class="element-loading"/>
        </div>
        <router-link
          v-else
          :to="create_correct_product_url(product, is_marketplace_environment)"
          :aria-label="`View product ${get_correct_translation_value(product, 'title', [selected_language])}`"
          @click.native="() => { $emit('handle_click'); click_on_image(product) }"
          itemprop="product link"
          :event="admin_imported ? '' : 'click'"
        >
          <div data-cy="product-slider-background-image" class="product-slider__background" :class="{ 'product-slider__background--admin': admin_imported && show_mobile }">
            <v-lazy-image
              class="product-slider__background-image"
              :src="get_correct_img(product)"
              :src-placeholder="default_image_src"
              :alt="get_correct_translation_value(product, 'title', [selected_language])"
            />
            <sale_ribon
              :price="get_price_including_tax_percentage(product)"
              :compare_at_price="get_compare_at_price_including_tax_percentage(product)"
            />
          </div>
          <div data-cy="product-slider-description" class="product-slider__description">
            <p>{{ get_correct_translation_value(product, 'title', [selected_language]) }}</p>
            <div>
              <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
              {{ get_price_including_tax_percentage(product) }}
              <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
              <span v-if="get_compare_at_price_including_tax_percentage(product)" class="product-slider__description--sale">
                <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
                {{ get_compare_at_price_including_tax_percentage(product) }}
                <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
              </span>
            </div>
          </div>
        </router-link>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import VLazyImage from "v-lazy-image/v2/v-lazy-image.js"
import { Carousel, Slide } from "../slider/index"
import calculate_percentage from "../../../Client/methods/calculate_percentage"
import create_correct_product_url from "../../../Client/methods/create_correct_product_url"
import { currency_signs, default_image_src, lang_to_currency_mapper } from "../../constants/other"
import { CLIENT_STORE } from "../../../Client/constants/other"
import sale_ribon from "./product_sale_ribon"
import get_correct_img from "../../methods/get_correct_first_image"
import { MARKETPLACE } from "../../../Marketplace/constants/other"
import is_currency_symbol from "../../methods/is_currency_symbol"
import get_correct_translation_value from "../../methods/get_correct_translation_value"
import { ADMIN, CONFIGURATION_STORE } from "../../../Admin/constants/others_constants"
import { ga_event_actions, ga_event_categories } from "../../constants/ga_constants"
import { UPDATE_VIEWED_PRODUCT } from "../../../Client/stores/Client/constants"
import round_to_two_decimals from "../../../Client/methods/round_to_two_decimals"
import { get_ga_instance } from "../../../../google_tracking"
import { default_language } from "../../../../../functions/constans/other"

export default {
  components: {
    Carousel,
    Slide,
    sale_ribon,
    VLazyImage
  },
  props: {
    products: {
      type: Array,
      required: true
    },
    load_right_away: {
      type: Boolean,
      required: false,
      default: false
    },
    admin_imported: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    show_mobile: {
      type: Boolean,
      required: false,
      default: false
    },
    showing_user_guide: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      default_image_src
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, ["translations", "selected_language"]),
    ...mapState([
      "is_marketplace_environment",
    ]),
    ...mapState(CONFIGURATION_STORE, {
      admin_translations: "translations"
    }),
    ...mapState(MARKETPLACE, [
      "marketplace_currency",
    ]),
    ...mapState(ADMIN, ["admin_language"]),
    get_translations() {
      return this.translations || this.admin_translations
    },
    currency_symbol() {
      if (this.showing_user_guide) return currency_signs[lang_to_currency_mapper[this.admin_language]]

      return this.is_marketplace_environment ?
        currency_signs[this.marketplace_currency] :
        (this.get_translations.currency_symbol || this.get_translations.currency_code)
    },
    get_products() {
      return this.products
    },
    decide_products_per_slide() {
      if (this.admin_imported && this.show_mobile) return [[300, 2]]

      return this.admin_imported && !this.show_mobile ? [[300, 4]] : [[300, 1], [450, 2], [850, 3], [1080, 4]]
    }
  },
  methods: {
    ...mapMutations(CLIENT_STORE, {
      update_viewed_product_data: UPDATE_VIEWED_PRODUCT,
    }),
    calculate_percentage,
    create_correct_product_url,
    get_correct_img,
    is_currency_symbol,
    get_correct_translation_value,
    get_first_variant(variants) {
      if (!variants[0].translations) return variants[0]

      if (variants[0].translations[this.selected_language]) return variants[0].translations[this.selected_language]
      
      return variants[0].translations[Object.keys(variants[0].translations)[0]]
    },
    /*
     * 
     */
    click_on_image(product) {
      get_ga_instance().then((ga) => {
        ga.track(ga_event_categories.product_slider, {
          product: product.id,
          product_name: get_correct_translation_value(product, "title", [this.selected_language]),
          actions: ga_event_actions.click,
        })
      })

      this.update_viewed_product_data(product)
    },
    get_compare_at_price_including_tax_percentage(product) {
      let temp_price = get_correct_translation_value(product.variants[0], 'compare_at_price', [this.selected_language], false)

      if (this.get_translations.tax_percantage) temp_price = temp_price * (
        1 + (Number(this.get_translations.tax_percantage) / 100)
      )

      return round_to_two_decimals(temp_price)
    },
    get_price_including_tax_percentage(product) {
      // default_language for when creating user guide product
      let temp_price = get_correct_translation_value(
        product.variants[0],
        "price",
        [this.selected_language || default_language],
        false
      )

      if (this.get_translations.tax_percantage) temp_price = temp_price * (
        1 + (Number(this.get_translations.tax_percantage) / 100)
      )

      return round_to_two_decimals(temp_price)
    },
  },
};
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/rise_up_effect.scss";
@use "../../../../styles/element_loading.scss";

.product-slider {
  width: 100%;
  margin: 0 0 40px;

  $image-dimensions: 240px;

  &__background {
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    overflow: hidden;
    border: 1px solid var(--light_grey, $grey);
    background-color: $pure-white;
    border-radius: $border-radius;
    transition: $default-transition;

    &-image {
      height: $image-dimensions !important;
      width: $image-dimensions !important;
    }

    &:hover {
      @extend .rise-up-effect;
    }
  }

  &__description {
    color: var(--font_heavy);
    font-size: 15px;

    p {
      max-width: 200px;
      margin: 20px auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    div {
      font-weight: bold;
    }

    &--sale {
      margin-left: 5px;
      text-decoration: line-through;
      color: var(--grey);
      font-weight: normal;
      font-size: 13px;
    }
  }

  h1 {
    padding: 0;
    margin: 0 5px;
  }

  &__loader {
    * {
      margin: auto;

      &:nth-child(2) {
        margin: 20px 0!important;
      }
    }

    p {
      height: 17px;
    }
  }

  .VueCarousel {
    max-width: $maximum-width;
    margin: auto;

    &-wrapper {
      max-width: $maximum-width;
      margin: auto;
      padding-top: 20px;
    }

    &-dot {
      background-color: var(--font_heavy);
    }

    &-slide.product-slider__slide {
      position: relative;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      padding: 0 10px;
      box-sizing: border-box;
      text-align: center;

      &--admin {
        width: 50%;
      }

      &:first-child {
        border-left: 0;
      }

      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    &-navigation {
      width: 100%;
      max-width: $maximum-width;
      margin: auto;

      &-next, &-prev {
        height: 24px;
        width: 24px;
        padding: 0 !important;
        transform: none !important;
        background: url("/static/icons/arrow.svg") no-repeat 100% 100%;
      }

      &-prev {
        transform: rotate(180deg) !important;
      }

      &--disabled {
        opacity: 0.1 !important;
      }

      &-button {
        filter: drop-shadow(-1px 0 5px var(--body_color));
      }
    }
  }
}
</style>
