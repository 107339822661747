<template>
  <section class="website-content">
    <save_button
      :show="show_save_button && can_user_manage_content"
      :section="$translate('side_menu.store_layout_admin')"
      @save="save_changed_content"
      @cancel="re_fetch_content"
    />
    <error_bar :error_list="error_list"/>

    <store_live_preview_editors v-if="data_loaded" :component_type="content_type">
      <menu_content v-if="content_type === menu" :can_user_manage_content="can_user_manage_content"/>
      <footer_content v-if="content_type === footer" :can_user_manage_content="can_user_manage_content"/>
      <collection_banners v-if="content_type === collection_banner" :can_user_manage_content="can_user_manage_content"/>
      <special_message_banner_component v-if="content_type === special_message_banner" :can_user_manage_content="can_user_manage_content"/>
      <popular_products_component v-if="content_type === popular_products" :can_user_manage_content="can_user_manage_content"/>
      <highlighted_products_component v-if="content_type === highlighted_products" :can_user_manage_content="can_user_manage_content"/>
      <story_banner_component v-if="content_type === story_banner" :can_user_manage_content="can_user_manage_content"/>
      <important_message_bar_component v-if="content_type === important_message_bar" :can_user_manage_content="can_user_manage_content"/>
      <about_page_configuration v-if="content_type === about_page" :can_user_manage_content="can_user_manage_content"/>
    </store_live_preview_editors>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import menu_content from "../utils/website_content/menu_content"
import footer_content from "../utils/website_content/footer_content"
import collection_banners from "../utils/website_content/collection_banners"
import special_message_banner_component from "../utils/website_content/special_message_banner"
import popular_products_component from "../utils/website_content/popular_products"
import highlighted_products_component from "../utils/website_content/highlighted_products"
import story_banner_component from "../utils/website_content/story_banner"
import important_message_bar_component from "../utils/website_content/important_message_bar"
import about_page_configuration from "../utils/website_content/about_page_configuration"
import {
  menu, footer, collection_banner, special_message_banner,
  popular_products, highlighted_products, ADMIN_STORE, CONTENT_STORE,
  story_banner, important_message_bar, CONFIGURATION_STORE, about_page, USER_STORE, PRODUCTS_STORE,
  shopify, imported
} from "../../constants/others_constants"
import save_button from "../utils/save_button"
import error_bar from "../utils/error_bar"
import { FETCH_CONTENT_DATA, SAVE_CONTENT } from "../../stores/Admin/content/constants"
import {
  CONTENT_DATA_UPDATED, UPDATE_LOADER, FETCH_CONFIGURATION_DATA
} from "../../stores/Admin/constants"
import { SAVE_CONFIGURATION } from "../../stores/Admin/configuration/constants"
import { FETCH_IMPORTED_PRODUCTS } from "../../stores/Admin/products/constants"
import project_styles from "../../../../data/project_styles"
import store_live_preview_editors from "../layout/store_live_preview_editors"

export default {
  components: {
    menu_content,
    footer_content,
    collection_banners,
    special_message_banner_component,
    save_button,
    error_bar,
    popular_products_component,
    highlighted_products_component,
    story_banner_component,
    important_message_bar_component,
    about_page_configuration,
    store_live_preview_editors
  },
  data() {
    return {
      show_preview: true,
      show_mobile_preview: true,
      menu,
      footer,
      collection_banner,
      special_message_banner,
      popular_products,
      highlighted_products,
      story_banner,
      important_message_bar,
      about_page
    }
  },
  computed: {
    ...mapState(CONTENT_STORE, {
      error_list: "error_list",
      header_items: "header_items",
      footer_content: "footer_content",
      banners: "banners",
      popular_products_data: "popular_products",
      highlighted_products_data: "highlighted_products",
      important_message_bar_data: "important_message_bar",
      about_page_content: "about_page_content"
    }),
    ...mapState(CONFIGURATION_STORE, [
      "single_product_homepages",
      "couple_products_homepages",
      "shopify_config",
      "project_styles"
    ]),
    ...mapState(PRODUCTS_STORE, [
      "imported_products",
      "shopify_products",
    ]),
    ...mapState(ADMIN_STORE, [
      "user_edited_content",
      "user_edited_special_type_homepages",
    ]),
    ...mapState(USER_STORE, ["permissions"]),
    show_save_button() {
      return !!Object.values(this.user_edited_content).length &&
        Object.values(this.user_edited_content).every(val => val) &&
        !this.error_list.length
    },
    content_type() {
      return this.$route.params.content_type
    },
    can_user_manage_content() {
      return this.permissions.layout_and_content.manage
    },
    products_type() {
      return this.shopify_config.should_use_shopify_data ? shopify : imported
    },
    data_loaded() {
      return this[`${this.products_type}_products`] && this.footer_content
    }
  },
  created() {
    if (!this.permissions.layout_and_content.module) this.$router.push("/admin")
  },
  async mounted() {
    if (!this[`${this.products_type}_products`]) await this.fetch_products()
    if (!this.footer_content) {
      this.update_loader()
      await this.fetch_content()
      this.update_loader(false)
    }
    if (!this.project_styles) {
      this.update_loader()
      await this.fetch_configuration()
      this.update_loader(false)
    }

    Object.entries((this.project_styles || project_styles).colors).forEach(([key, val]) =>
      document.documentElement.style.setProperty(`--${key}`, val)
    )
  },
  methods: {
    ...mapMutations(ADMIN_STORE, {
      content_data_updated: CONTENT_DATA_UPDATED,
      update_loader: UPDATE_LOADER
    }),
    ...mapActions(CONTENT_STORE, {
      save_content: SAVE_CONTENT,
      fetch_content: FETCH_CONTENT_DATA
    }),
    ...mapActions(CONFIGURATION_STORE, {
      save_configuration: SAVE_CONFIGURATION,
      fetch_configuration: FETCH_CONFIGURATION_DATA
    }),
    ...mapActions(PRODUCTS_STORE, {
      fetch_imported_data: FETCH_IMPORTED_PRODUCTS
    }),
    switch_view(value) {
      this.show_mobile_preview = value
    },
    async fetch_products() {
      this.update_loader(true)

      await this[`fetch_${this.products_type}_data`]()

      this.update_loader(false)
    },
    async save_changed_content() {
      this.save_content([{
        header_items: this.header_items,
        footer_content: this.footer_content,
        banners: this.banners,
        popular_products: this.popular_products_data,
        highlighted_products: this.highlighted_products_data,
        important_message_bar: this.important_message_bar_data,
        about_page_content: this.about_page_content
      }, !this.user_edited_special_type_homepages])

      // If user added a direct link to single or couple product page
      if (this.user_edited_special_type_homepages) this.save_configuration({
        single_product_homepages: this.single_product_homepages,
        couple_products_homepages: this.couple_products_homepages,
      })
    },
    async re_fetch_content() {
      await this.fetch_content()
      this.content_data_updated({})
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;

  .website-content {
    &__grid {
      display: grid;
      grid-template-columns: calc(100% - #{$iphone-width + $double-default-size + $half-default-size}) $half-default-size #{$iphone-width + $double-default-size};

      @media (max-width: $tablet--small) {
        grid-template-columns: 0 1px calc(100% - 1px);
      }

      &--closed {
        margin-left: 0 !important;
        grid-template-columns: calc(100% - $half-default-size) $half-default-size 0 !important;

        .website-content__preview-closer {
          transform: translate(-80%, calc(50vh - 50%));
        }
      }

      &--full-screen {
        margin-left: -#{$material-size};
        grid-template-columns: 0 $half-default-size calc(100% - $half-default-size);
      }

      &--full-width {
        grid-template-columns: 0 $half-default-size #{$iphone-width + $double-default-size};

        @media (max-width: $tablet--small) {
          grid-template-columns: 0 1px calc(100% - 1px);
        }
      }
    }

    &__preview-closer {
      display: flex;
      align-content: center;
      transform: translate(-#{$material-button / 2}, calc(50vh - 50%));
      z-index: $z-index--bottom;
      width: $material-button;
      height: $material-button;
      background: $pure-white;
      border-radius: 50%;
      box-shadow: 0 0 20px -5px $shadow-color;
      transition: 200ms ease-in-out;
      cursor: pointer;

      .md-icon {
        min-width: 15px;
        width: 15px;
        transform: translateX(2px);
      }
    }

    &__editors {
      position: relative;
      width: 100%;
      overflow: hidden;
    }
  }
</style>
