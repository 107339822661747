<template>
  <section class="special-message-banner-config scroll-content">
    <h3 class="md-display-1">{{ $translate("special_message_banner.title") }}</h3>

    <section_description_preview :dense="true">
      <div class="md-body-1">{{ $translate("special_message_banner.message") }}</div>
    </section_description_preview>

    <div class="special-message-banner-config__actions">
      <md-button
        v-if="can_user_manage_content"
        data-cy="add-special-banner"
        class="md-fab md-raised md-primary special-message-banner-config__image-button"
        @click="adding_banner = true"
      >
        <md-icon>add</md-icon>
        <md-tooltip md-direction="top">{{ $translate("special_message_banner.add_banner") }}</md-tooltip>
      </md-button>
      <div class="special-message-banner-config__images">
        <draggable v-model="assembled_banners" group="images" @start="drag=true" @end="drag=false">
          <transition-group>
            <div
              v-for="({ mobile_image, src }, ind) in assembled_banners"
              :key="`${ind}-image`"
              class="special-message-banner-config__image"
            >
              <img
                class="md-layout-item"
                :src="mobile_image || src"
                crossOrigin="anonymous"
              >
              <md-speed-dial md-event="click" md-effect="scale" md-direction="bottom" class="special-message-banner-config__image-actions">
                <md-speed-dial-target class="md-plain">
                  <md-icon>more_vert</md-icon>
                </md-speed-dial-target>

                <md-speed-dial-content>
                  <md-button v-if="can_user_manage_content" class="md-icon-button" @click="() => banner_index = ind">
                    <md-icon>edit</md-icon>
                    <md-tooltip md-direction="top">{{ $translate("special_message_banner.edit_banner") }}</md-tooltip>
                  </md-button>

                  <md-button v-if="can_user_manage_content" class="md-icon-button" @click="delete_special_message_banner(ind)" data-cy="delete-banner">
                    <md-icon>delete_outline</md-icon>
                    <md-tooltip md-direction="top">{{ $translate("special_message_banner.delete_banner") }}</md-tooltip>
                  </md-button>
                </md-speed-dial-content>
              </md-speed-dial>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>
    <md-dialog
      :md-active="open_banner_modal"
      :md-click-outside-to-close="true"
    >
      <md-dialog-title>
        {{ 
          adding_banner ?
            $translate("special_message_banner.add_banner_title") :
            $translate("special_message_banner.edit_banner")
        }}
      </md-dialog-title>

      <section class="special-message-banner-config__modal-content">
        <hr/>
        
        <div
          class="special-message-banner-config__image-edit"
        >
          <div>
            <div v-if="changing_banner_image || (banner_index === null && !uploaded_image.src)" class="image-upload">
              <input
                data-cy="special-banner-image-upload"
                type="file"
                id="image-upload"
                accept="image/*"
                @drop="event => add_image(event.target.files[0])"
                @input="event => add_image(event.target.files[0])"
                v-cloak
              >
              <label for="image-upload">
                <md-icon>cloud_upload</md-icon>
                {{ $translate("drag_and_drop_image") }}
              </label>
            </div>
            <div v-else>
              <img
                class="md-layout-item"
                :src="uploaded_image.src || edited_banner.mobile_image || edited_banner.src"
              >
              <md-button
                class="md-raised md-primary"
                @click="() => { changing_banner_image = true; uploaded_image = {} }"
              >
                {{ $translate("special_message_banner.change_banner") }}
              </md-button>
            </div>
          </div>
          <div>
            <md-field class="md-focused">
              <label>{{ $translate("special_message_banner.alt_text_label") }}:</label>
              <md-input
                data-cy="special-banner-alt-text"
                v-model="alt_text"
                md-autogrow
                :placeholder="$translate('special_message_banner.alt_text')"
                @change="({ target }) => alt_text = target.value"
              />
            </md-field>
            <hr />
            <div class="special-message-banner-config__other-actions">
                <md-button
                  v-if="!selecting_link && !banner_link"
                  class="md-raised md-primary"
                  @click="selecting_link = true"
                >
                  {{ $translate("special_message_banner.links.title") }}
                </md-button>
                <div v-else-if="selecting_link">
                  <md-button
                    class="md-raised md-primary"
                    @click="() => {open_entity_selector = true; hide_banner_modal = true;}"
                  >
                    {{ $translate("special_message_banner.links.product_or_collection") }}
                  </md-button>
                  <md-button
                    class="md-raised md-primary"
                    @click="link_to_about_page"
                  >
                    {{ $translate("special_message_banner.links.about_page") }}
                  </md-button>
                  <div class="special-message-banner-config__external-link">
                    <md-field class="md-focused">
                      <label>{{ $translate("special_message_banner.links.external") }}:</label>
                      <md-input
                        data-cy="special-banner-external-link-input"
                        v-model="banner_link"
                        md-autogrow
                        :placeholder="$translate('special_message_banner.links.external_placeholder')"
                      />
                    </md-field>
                    <md-button
                      data-cy="special-banner-external-link-add"
                      class="md-raised md-primary"
                      :disabled="!banner_link"
                      @click="() => { selecting_link = false; external_link = true }"
                    >
                      {{ $translate("add") }}
                    </md-button>
                  </div>
                </div>
                <div v-else class="special-message-banner-config__link-remove">
                  <p>{{ $translate("special_message_banner.links.selected_link") }}: <span>{{ banner_link }}</span></p>
                  <md-button
                    class="md-raised md-accent"
                    @click="banner_link = ''"
                  >
                    {{ $translate("special_message_banner.links.remove") }}
                  </md-button>
                </div>
            </div>
          </div>
        </div>
        <md-checkbox v-model="show_on_single_and_couple">
          {{ $translate("special_message_banner.shown_on_single_and_couple") }}
        </md-checkbox>

        <hr/>
      </section>

      <md-dialog-actions>
        <md-button class="md-accent" @click="close_modal">{{ $translate("cancel") }}</md-button>
        <md-button
          class="md-primary"
          data-cy="create-special-banner"
          @click="save_banner"
          :disabled="!(uploaded_image.src || edited_banner.src) || changing_banner_image && !uploaded_image.src"
        >{{ adding_banner ? $translate("add") : $translate("done") }}</md-button>
      </md-dialog-actions>
    </md-dialog>

    <entity_selection_modal
      v-if="can_user_manage_content"
      :modal_title="$translate('popular_products.select_product')"
      :open_modal="open_entity_selector"
      :enable_collection_selection="true"
      @entity_selected="entity_selected"
      @toggle_modal="() => { open_entity_selector = !open_entity_selector; hide_banner_modal = open_entity_selector }"
    />
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { CONTENT_STORE, ADMIN, CONFIGURATION_STORE, CONTENT } from "../../../constants/others_constants"
import { CONTENT_DATA_UPDATED, UPDATE_IMAGES_FOR_DELETE, UPDATE_LOADER, USER_EDITED_SPECIAL_HOMEPAGES } from "../../../stores/Admin/constants"
import {
  DELETE_SPECIAL_MESSAGE_BANNER, CREATE_SPECIAL_MESSAGE_BANNER,
  UPDATE_SPECIAL_MESSAGE_BANNER_LASER,
  UPDATE_SPECIAL_MESSAGE_BANNER_USE_ON_PAGES,
  REARRANGE_SPECIAL_MESSAGE_BANNER,
  UPDATE_SPECIAL_MESSAGE_BANNER,
  EDITED_PAGE_TRANSLATIONS
} from "../../../stores/Admin/content/constants"
import upload_image from "../../../methods/upload_image"
import { image_dimension_names, page_types } from "../../../../../data/other_constants"
import entity_selection_modal from "../entity_selection_modal"
import { collection, product } from "../../../../Shared/constants/other"
import get_curly_brace_content from "../../../../Shared/methods/get_curly_brace_content"
import { UPDATE_PAGE_TYPE_TRANSLATION } from "../../../stores/Admin/configuration/constants"
import section_description_preview from "../section_description_preview"

export default {
  components: {
    section_description_preview,
    entity_selection_modal
  },
  props: {
    can_user_manage_content: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      changing_banner_image: false,
      adding_banner: false,
      show_on_single_and_couple: false,
      open_entity_selector: false,
      selecting_link: false,
      external_link: false,
      alt_text: "",
      selected_entity: null,
      banner_index: null,
      uploaded_image: {},
      banner_link: "",
      hide_banner_modal: false
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["project_config"]),
    ...mapState(CONTENT_STORE, ["banners"]),
    assembled_banners: {
      get() {
        if (!this.banners?.special_message_banner) return []

        const is_banner_array = this.banners?.special_message_banner?.constructor === Array

        return (is_banner_array ?
          this.banners?.special_message_banner :
          [this.banners?.special_message_banner])
      },
      set(updated_arrangement) {
        this.rearrange_banner(updated_arrangement)
        this.content_data_updated({ types_updated: ["banners"] })
      }
    },
    open_banner_modal() {
      return (this.adding_banner || this.banner_index !== null) && !this.hide_banner_modal
    },
    edited_banner() {
      return this.adding_banner ? {} : this.assembled_banners?.[this.banner_index] || {}
    }
  },
  watch: {
    banner_index(val) {
      if (val !== null) {
        this.alt_text = this.edited_banner.alt_text
        this.show_on_single_and_couple = this.edited_banner.show_on_single_and_couple
        this.banner_link = this.edited_banner.link
      }
    }
  },
  methods: {
    ...mapMutations(CONTENT_STORE, {
      create_banner: CREATE_SPECIAL_MESSAGE_BANNER,
      rearrange_banner: REARRANGE_SPECIAL_MESSAGE_BANNER,
      update_banner: UPDATE_SPECIAL_MESSAGE_BANNER,
      delete_banner: DELETE_SPECIAL_MESSAGE_BANNER,
      update_laser: UPDATE_SPECIAL_MESSAGE_BANNER_LASER,
      update_use_on_special_pages: UPDATE_SPECIAL_MESSAGE_BANNER_USE_ON_PAGES,
      edited_page_translations: EDITED_PAGE_TRANSLATIONS
    }),
    ...mapMutations(ADMIN, {
      content_data_updated: CONTENT_DATA_UPDATED,
      update_loader: UPDATE_LOADER,
      image_delete_toggle: UPDATE_IMAGES_FOR_DELETE,
      user_edited_special_homepages: USER_EDITED_SPECIAL_HOMEPAGES,
    }),
    ...mapMutations(CONFIGURATION_STORE, {
      update_page_type: UPDATE_PAGE_TYPE_TRANSLATION,
    }),
    close_modal() {
      this.adding_banner = false
      this.banner_index = null
      this.alt_text = ""
      this.show_on_single_and_couple = false
      this.banner_link = ""
      this.selected_entity = null
      this.uploaded_image = {}
      this.external_link = false
    },
    delete_special_message_banner(index) {
      const correct_banner = this.banners.special_message_banner[index]

      // Dont delete img source if image not uploaded yet
      if (!correct_banner.image_file) this.image_delete_toggle({
        images: [
          correct_banner?.src,
          correct_banner?.mobile_image
        ],
        store_type: CONTENT
      })

      this.delete_banner(index)
      this.content_data_updated({ types_updated: ["banners"] })
    },
    link_to_about_page() {
      this.banner_link = "/about"
      this.selecting_link = false
      this.external_link = false
    },
    entity_selected(collection_data, product_data, link_to_microsite, selecting_collection, selecting_parent_collection) {
      const parent_collection = get_curly_brace_content(collection_data.body_html)
      const category = selecting_parent_collection ?
        parent_collection[1] :
        parent_collection ? `${parent_collection[1]}/${collection_data.handle}` : collection_data.handle
      const sub_category = (selecting_parent_collection || selecting_collection) ?
        undefined : (product_data.buffer_id || product_data.id)
      const route = link_to_microsite ? 
        `/?${selecting_collection ? collection : product}=${
          selecting_collection ? collection_data.handle : (product_data.buffer_id || product_data.id)
        }`
        : undefined

      if (link_to_microsite) {
        this.update_page_type([
          product_data ? page_types.single_product_homepages : page_types.couple_products_homepages,
          product_data ? (product_data.buffer_id || product_data.id) : collection_data.handle,
        ])
        this.user_edited_special_homepages(true)
      }

      this.banner_link = route || `/Products/${sub_category ? `${category}/${sub_category}` : category}`
      this.open_entity_selector = false
      this.hide_banner_modal = false
      this.external_link = false
      this.selecting_link = false
    },
    add_image(value) {
      try {
        const reader = new FileReader()

        reader.addEventListener("load", () => {
          this.uploaded_image = {
            src: reader.result,
            file: value,
          }
          this.changing_banner_image = false
        }, false)

        reader.readAsDataURL(value)
      } catch (error) {
        console.log(error)
      }
    },
    save_banner() {
      this.adding_banner ?
        this.create_banner({
          alt_text: this.alt_text,
          image: this.uploaded_image,
          src: this.uploaded_image.src,
          show_on_single_and_couple: this.show_on_single_and_couple,
          link: this.banner_link,
          external_link: this.external_link
        }) :
        this.update_banner([
          this.banner_index,
          {
            alt_text: this.alt_text,
            show_on_single_and_couple: this.show_on_single_and_couple,
            link: this.banner_link,
            external_link: this.external_link,
            ...(this.uploaded_image.src ?
              {
                image_file: this.uploaded_image.file,
                src: this.uploaded_image.src
              } :
              {
                src: this.edited_banner?.src,
                mobile_image: this.edited_banner?.mobile_image
              }
            )
          }
        ])

      this.content_data_updated({ types_updated: ["banners"] })
      this.close_modal()
    },
    /**
     * 
     */
    async save_image() {
      this.update_loader(true)
      this.image_delete_toggle({
        images: [
          this.special_message_banner?.src,
          this.special_message_banner?.mobile_image
        ],
        store_type: CONTENT
      })

      const image_sources = await upload_image(
        this.uploaded_image,
        `banners/special_message/${this.uploaded_image.file.name}`,
        false,
        false
      )

      if (!image_sources) return this.update_loader(false)

      this.create_banner([
        image_sources[image_dimension_names.desktop],
        image_sources[image_dimension_names.tablet]
      ])
      this.content_data_updated({ types_updated: ["banners"] })
      this.changing_banner_image = false
      this.update_loader(false)
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/admin_image_upload.scss";

  .special-message-banner-config {
    &__preview {
      display: block;
      margin: auto;
      max-width: 800px;
      max-height: 600px;
      width: 100%;
    }

    &__modal-content {
      text-align: center;
    }

    &__link-remove {
      p {
        width: 100%;
        max-width: 300px;
        height: 30px;
        line-height: 30px;
        overflow-x: scroll;
        overflow-y: hidden;
        text-overflow: ellipsis;
        margin: 0 0 $half-default-size;
        text-align: left;
        font-weight: bold;

        span {
          font-weight: normal;
          max-width: 200px;
          display: inline-block;
          vertical-align: top;
          text-overflow: ellipsis;
          height: 30px;
        }
      }
    }

    &__external-link {
      display: grid;
      grid-template-columns: 1fr 50px;
      column-gap: $half-default-size;
      align-items: baseline;
      
      .md-button {
        width: 50px;
        min-width: 50px;
        margin: 0;
      }
    }

    &__other-actions {
      position: relative;

      .md-button {
        width: 100%;
        margin: 0 0 $half-default-size;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      padding: $default-size $default-size 0 0;

      & > div {
        max-width: calc(100% - 120px);
        margin-left: $default-size;
      }

      @media (max-width: $tablet) {
        display: block;
        padding: 0;
      }
    }

    .special-message-banner {
      max-width: 800px;
      max-height: 500px;
      margin: auto;
    }

    &__image {
      $image-size: 300px;

      position: relative;
      display: inline-block;
      max-width: $image-size;
      height: calc(#{$image-size} - 100px);
      margin: 0 $default-size $default-size 0;
      vertical-align: top;

      &s {
        max-width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: $half-default-size;
        height: calc(#{$image-size} - 100px + #{$default-size});
        white-space: nowrap;

        @media (max-width: $tablet) {
          overflow: visible;
          white-space: normal;
          height: auto;
          padding: 0;
          margin-left: $half-default-size;
        }
      }

      &-edit {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: $half-default-size;
        margin: $default-size $half-default-size;

        @media (max-width: $tablet--small) {
          grid-template-columns: 1fr;
        }

        img {
          display: block;
          max-width: 200px;
          max-height: 200px;
          margin: auto;
          border-radius: $border-radius;
          object-fit: contain;
          object-position: center;
          box-shadow: 0 0px 10px 0px $shadow-color;
        }
      }

      &-actions {
        position: absolute;
        top: -5px;
        right: 10px;
        opacity: 0;
        transition: 250ms ease-in-out;
        cursor: pointer;

        .md-fab {
          width: 40px;
          height: 40px;
        }
      }

      &-button {
        margin: 0 $default-size $default-size !important;
        vertical-align: middle !important;
      }

      img {
        max-width: $image-size;
        height: calc(#{$image-size} - 100px) !important;
        padding: $half-default-size;
        border-radius: $border-radius;
        object-fit: contain;
        object-position: center;
        box-shadow: 0 0px 10px 0px $shadow-color;
        cursor: grab;
      }

      &:hover {
        .special-message-banner-config__image-actions {
          top: 10px;
          opacity: 1;
        }
      }
    }
  }

  @keyframes slide-link-buttons {
    from {
      position: absolute;
      top: 76px;
      left: 0;
      opacity: 0;
      transform: translateY(-40px);
    }
    to {
      position: relative;
      opacity: 1;
      transform: translateY(0);
    }
  }

  .link-buttons-enter-active {
    animation: slide-link-buttons .5s;
  }
  .link-buttons-leave-active {
    animation: slide-link-buttons .5s reverse;
  }

  .special-banner-fade-enter-active, .special-banner-fade-leave-active {
    transform: translateY(0);
    transition: opacity .5s ease-out, transform .5s ease-out;
  }
  .special-banner-fade-enter, .special-banner-fade-leave-to {
    position: absolute;
    top: 0;
    left: calc(50% - 125px);
    transform: translateY(-20px);
    opacity: 0;
  }
</style>
