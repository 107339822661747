<template functionals>
  <div class="sale-ribbon" v-if="final_percentage > 0">
    -{{ final_percentage }}%
  </div>
</template>

<script>
import calculate_percentage from "../../../Client/methods/calculate_percentage"

export default {
  props: {
    price: {
      type: [Number, String],
      required: true
    },
    compare_at_price: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    final_percentage() {
      return calculate_percentage(this.compare_at_price, this.price)
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;

  .sale-ribbon {
    position: absolute;
    top: 13px;
    left: -65px;
    width: 200px;
    padding: 5px 0;
    font-size: 13px;
    background: $alert-color;
    color: $pure-white;
    transform: rotate(-45deg);
    text-align: center;
  }
</style>
