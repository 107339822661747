<template>
  <section class="footer" :class="{ 'footer--admin': admin_imported && show_mobile }">
    <div class="footer__wrapper">
      <div class="footer__logo">
        <img
          :src="get_store_icon"
          :alt="`${get_translations.website_name} logo`"
          itemprop="logo"
        />
      </div>
      <div data-cy="footer-content" class="footer__content" ref="footer" v-html="correct_footer_data" />
    </div>
    <div class="footer__messsage">
      {{ get_translations.footer_bottom_message || `© ${year} ${get_translations.website_name}. All rights reserved.` }}

      <a
        :href="admin_link"
        class="link"
        itemprop="link"
        aria-label="Admin link"
        rel="nofollow"
        target="_blank"
      >Admin login</a>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { admin_domain, image_dimension_names } from "../../../../data/other_constants"
import { default_route_strings } from "../../../../data/routes/default-routes"
import default_footer_content from "../../../Admin/constants/empty_objects/default_footer_content"
import { CONFIGURATION_STORE, CONTENT_STORE } from "../../../Admin/constants/others_constants"
import { default_logo_src, SHARED_STORE } from "../../../Shared/constants/other"
import check_if_user_in_store_preview from "../../../Shared/methods/check_if_user_in_store_preview"
import get_image_src from "../../../Shared/methods/get_image_src"
import { CLIENT_STORE } from "../../constants/other"

export default {
  props: {
    admin_imported: {
      type: Boolean,
      required: false,
      default: false
    },
    show_mobile: {
      type: Boolean,
      required: false,
      default: false
    },
    guide_data: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return { default_logo_src }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "footer_content",
      "project_styles",
      "rest_config",
      "translations",
      "selected_language"
    ]),
    ...mapState(SHARED_STORE, ["store_id"]),
    ...mapState(CONFIGURATION_STORE, {
      admin_rest_config: "rest_config",
      admin_translations: "translations"
    }),
    ...mapState(CONTENT_STORE, { admin_footer_content: "footer_content" }),
    year() {
      return new Date().getFullYear()
    },
    get_translations() {
      return this.translations || this.admin_translations
    },
    correct_footer_data() {
      const data = this.admin_imported ? this.admin_footer_content : this.footer_content
      return (typeof data === "string" ? data : data[this.selected_language]) || default_footer_content
    },
    admin_link() {
      return check_if_user_in_store_preview() ? admin_domain : `${window.location.origin}/admin`
    },
    get_store_icon() {
      const icon = ((this.admin_imported ? this.admin_rest_config : this.rest_config) || {}).icon

      return (icon && typeof icon === "string") ?
        icon :
        this.guide_data || get_image_src(icon, image_dimension_names.mobile, true)
    },
  },
  mounted() {
    // Add store id or store name id to outside links
    if (check_if_user_in_store_preview()) Array
      .from(document.querySelectorAll(".footer a"))
      .forEach(link => {
        if (
          [
            default_route_strings.contact_us,
            default_route_strings.legal_page
          ].some(page_type => link.href.includes(`${window.origin}/${page_type}`))
        ) link.href = `${window.origin}/${this.store_id}${link.href.split(window.origin)[1]}`
      })
  }
}
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;

$class: ".footer";

#{$class} {
  margin-top: 100px;
  padding: 50px 0;
  background-color: var(--background_heavy) !important;
  color: var(--item_name_color) !important;
  text-align: left;

  @media (max-width: $maximum-width) {
    padding: 40px 20px;
  }
  @media (max-width: $tablet--small) {
    padding: 30px 20px;
  }

  &--admin {
    pointer-events: none;
    margin-top: 30px;

    #{$class} {
      &__wrapper {
        padding: 0 10px 30px;
      }
      &__logo {
        display: block;
        width: 100%;
        margin: 0 auto 30px;

        img  {
          display: block;
          margin: auto;
        }
      }
      &__content {
        display: block;
        width: 100%;
      }
      &__column {
        width: calc(50% - 12px);
        margin-right: 5px;

        &:nth-child(even) {
          margin-left: 5px;
          margin-right: 0;
        }

        img {
          display: block;
          height: 30px;
          margin: 0 auto 10px;
        }
      }
      &__item {
        font-size: 15px;

        &:last-child {
          margin-bottom: 10px;
        }
      }

      &__messsage {
        padding: 20px 20px 0;
      }
    }
  }

  &__wrapper {
    max-width: $maximum-width;
    min-height: 50px;
    margin: auto;
    padding: 20px 0 40px;
    font-size: 14px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--background_light);

    @media (max-width: $tablet--small) {
      padding: 0 10px 30px;
    }
  }

  &__messsage {
    max-width: $maximum-width;
    padding: 20px 0 0;
    margin: auto;
    font-size: 12px;

    @media (max-width: $tablet--small) {
      padding: 20px 20px 0;
    }

    a {
      float: right;

      @media (max-width: $tablet--small) {
        float: unset;
      }
    }
  } 

  &__logo {
    display: inline-block;
    width: 120px;
    margin-right: 20px;

    @media (max-width: $tablet--small) {
      display: block;
      width: 100%;
      margin: 0 auto 30px;
    }

    img {
      max-width: $max-logo-width;
      height: 40px !important;
      vertical-align: middle;
      object-fit: contain;

      @media (max-width: $tablet--small) {
        display: block;
        margin: auto;
      }
    }
  }

  &__content {
    display: inline-block;
    width: calc(100% - 145px);
    vertical-align: top;
    text-align: right;

    @media (max-width: $tablet--small) {
      display: block;
      width: 100%;
    }
  }

  &__column {
    display: inline-block;
    margin-right: 30px;
    vertical-align: top;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $tablet--small) {
      width: calc(50% - 12px);
      margin-right: 5px;

      &:nth-child(even) {
        margin-left: 5px;
        margin-right: 0;
      }
    }

    h4 {
      margin: 0 0 10px;
      color: var(--item_name_color) !important;
    }

    img {
      display: inline-block;
      height: 30px;
      margin-right: 10px;
      vertical-align: middle;

      @media (max-width: $tablet--small) {
        display: block;
        height: 30px;
        margin: 0 auto 10px;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      text-align: left;
    }
  }

  &__link {
    color: var(--item_name_color) !important;
    text-decoration: none !important;
    line-height: normal;
  }

  &__item {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: normal;

    @media (max-width: $tablet) {
      padding: 5px;
      font-size: 14px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: $tablet--small) {
      font-size: 15px;

      &:last-child {
        margin-bottom: 10px;
      }
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      height: 15px;
      fill: var(--item_name_color);
    }
  }
}
</style>
