import validation_imports from "./validation_imports"
import transform_error_list from "../transform_errors_list"
import { MISSING_NAME } from "../../constants/validation/error_types"
import { CONTENT_STORE } from "../../constants/others_constants"

export default {
  ...validation_imports(CONTENT_STORE),
  async validate() {

    // Clear error list and reset validator
    this.clear_errors("header-items")

    // Defining validator without reactivity
    this.validator = true

    // Name - Checks that all menu items have its names filled out for every language
    if (
      !this.header_items.every(
        ({ name, translations }) => Object.keys(this.translations).every(lang => translations?.[lang]?.name) || name
      )
    ) this.transforming_errors(
      await MISSING_NAME(
        this.$translate("menu_content.menu_item"),
        "header-items",
        `${this.$translate("title_translation")} ${this.$translate("menu_content.for_language")}`
      )
    )
    // Category - Checks if category of any menu item is empty
    if (this.header_items.some(({ category }) => !category)) this.transforming_errors(
      await MISSING_NAME(this.$translate("menu_content.menu_item"), "header-items", this.$translate("route"))
    )

    // Name - Checks that all menu sub items have its names filled out for every language
    if (!this.header_items.flatMap(({ sub_items }) => sub_items || []).every(
      ({ name, translations }) => Object.keys(this.translations).every(lang => translations?.[lang]?.name) || name
    )) this.transforming_errors(
      await MISSING_NAME(
        this.$translate("menu_content.menu_subitem"),
        "header-items",
        `${this.$translate("title_translation")} ${this.$translate("menu_content.for_language")}`
      )
    )
    // Subcategory - Checks if sub category of any menu subitem is empty
    if (this.header_items.flatMap(({ sub_items }) => sub_items || []).some(({ sub_category }) => !sub_category)) this.transforming_errors(
      await MISSING_NAME(this.$translate("menu_content.menu_subitem"), "header-items", this.$translate("route"))
    )
  
    return this.validator
  },
  transforming_errors(error, remove) {
    this.update_errors(transform_error_list(this.get_error_list(), error, remove, "menu-content"))
    this.validator = false
  }
}
