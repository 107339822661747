var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data_loaded)?_c('section',{staticClass:"about-page",class:{ 'about-page--admin': _vm.admin_imported && _vm.show_mobile }},[((_vm.get_about_page_content.top_banner || {}).src)?_c('img',{staticClass:"about-page__banner",attrs:{"src":_vm.get_about_page_content.top_banner[_vm.is_mobile_device ? 'thumb_src' : 'src'],"alt":"top banner image"}}):_vm._e(),_c('p',{staticClass:"about-page__title",class:{
      'about-page__title--negative-margin': (_vm.get_about_page_content.top_banner || {}).src
    }},[_vm._v(" "+_vm._s(_vm.texts.title || "About")+" ")]),_c('br'),(_vm.texts.quote)?_c('p',{staticClass:"about-page__quote"},[_vm._v("„"+_vm._s(_vm.texts.quote)+"“")]):_vm._e(),(_vm.top_paragraph)?_c('div',{staticClass:"about-page__top-paragraph"},[(_vm.split_string_into_two(_vm.top_paragraph).length === 1)?_c('div',[_vm._v(_vm._s(_vm.top_paragraph))]):_vm._l((_vm.split_string_into_two(_vm.top_paragraph)),function(paragraph,ind){return _c('p',{key:`about-top-paragraph-${ind}`},[_vm._v(" "+_vm._s(paragraph)+" ")])})],2):_vm._e(),(_vm.get_about_page_content.tile_pairs && _vm.get_about_page_content.tile_pairs.length)?_c('div',{staticClass:"about-page__tile-pairs"},_vm._l((_vm.get_about_page_content.tile_pairs),function(tiles,index){return _c('div',{key:`tile-pairs-${index}`,staticClass:"about-page__tile-pair"},_vm._l((tiles),function(tile,ind){return _c('div',{key:`tile-${index}-${ind}`,staticClass:"about-page__tile",class:{
          'about-page__tile--non-image': !tile || !tile.src,
          'about-page__tile--text-right': ind === 0
        }},[(tile && tile.src)?_c('img',{attrs:{"src":tile.src[!_vm.user_is_using_fast_connection && tile.src.thumb_url ? 'thumb_url' : 'image_url'],"alt":"tile image"}}):_c('p',[_vm._v(" "+_vm._s(tile ? tile[_vm.language] : "")+" ")])])}),0)}),0):_vm._e(),((_vm.get_about_page_content.bottom_banner || {}).src)?_c('img',{staticClass:"about-page__banner about-page__banner--bottom",attrs:{"src":_vm.get_about_page_content.bottom_banner[!_vm.user_is_using_fast_connection && _vm.get_about_page_content.bottom_banner.thumb_src ? 'thumb_src' : 'src'],"alt":"bottom banner image"}}):_vm._e(),(
      _vm.get_about_page_content.bottom_paragraphs &&
      _vm.get_about_page_content.bottom_paragraphs.length &&
      _vm.get_about_page_content.bottom_paragraphs.every(paragraph => paragraph[_vm.language] && paragraph[_vm.language].title)
    )?_c('div',{staticClass:"about-page__bottom-paragraphs",class:{ 'about-page__bottom-paragraphs--negative-margin': (_vm.get_about_page_content.bottom_banner || {}).src }},_vm._l((_vm.get_about_page_content.bottom_paragraphs),function(paragraph,ind){return _c('div',{key:`bottom-paragraph-${ind}`,staticClass:"about-page__bottom-paragraph"},[_c('p',[_vm._v(" "+_vm._s(paragraph[_vm.language].title)+" ")]),_c('div',[_vm._v(" "+_vm._s(paragraph[_vm.language].message)+" ")])])}),0):_vm._e()]):_c('div',[_c('div',{staticClass:"about-page__banner element-loading"}),_vm._m(0),_vm._m(1),_c('div',{staticClass:"about-page__banner about-page__banner--bottom element-loading"}),_vm._m(2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-page__top-paragraph"},[_c('p',{staticClass:"element-loading"}),_c('p',{staticClass:"element-loading"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-page__tile-pairs"},[_c('div',{staticClass:"about-page__tile-pair"},[_c('div',{staticClass:"about-page__tile about-page__tile--text-right element-loading"}),_c('div',{staticClass:"about-page__tile element-loading"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-page__bottom-paragraphs"},[_c('div',{staticClass:"about-page__bottom-paragraph element-loading"}),_c('div',{staticClass:"about-page__bottom-paragraph element-loading"}),_c('div',{staticClass:"about-page__bottom-paragraph element-loading"})])
}]

export { render, staticRenderFns }