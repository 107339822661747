<template>
  <section class="menu-content scroll-content">
    <h3 class="md-display-1">
      {{ $translate("menu_content.title") }}
      <video_tutorial section="menu_items" />
    </h3>

    <hr>

    <language_switcher
      @change-language="language => selected_language = language"
      :allow_only_enabled="true"
      :show_all_languages="false"
      :selected_language="selected_language"
      :bottom_border="true"
    />

    <div class="menu-content__items">
      <draggable v-model="get_header_items" group="header_items" @start="drag=true" @end="drag=false">
        <transition-group>
          <md-card v-for="({ category, sub_items, change_type, parent_category, ...rest }, index) in get_header_items" :key="`header-item-${index}`">
            <md-card-header>
              <md-card-actions>
                <div>
                  <md-field>
                    <label>{{ $translate("title_translation") }}</label>
                    <md-input
                      :value="get_correct_translation_value(rest, 'name', [selected_language], false)"
                      @change="event => update_menu_item('name', [index, event.target.value, undefined, selected_language])"
                      :disabled="change_type === deleted || !can_user_manage_content"
                    />
                  </md-field>
                  <!-- Disabling possibility to adjust route -->
                  <!-- <md-field>
                    <label>{{ $translate("route") }}</label>
                    <md-input
                      :value="category"
                      @change="event => update_menu_item('category', [index, event.target.value, selected_language])"
                      :disabled="change_type === deleted || !can_user_manage_content"
                    />
                  </md-field> -->
                </div>
                <div class="menu-content__items__buttons">
                  <md-button
                    v-if="can_user_manage_content"
                    class="md-icon-button md-raised"
                    @click="delete_or_restore([index])"
                    :class="{
                      'md-primary': change_type === deleted,
                      'md-accent': change_type !== deleted
                    }"
                  >
                    <md-icon>{{ change_type === deleted ? "restore" : "delete"}}</md-icon>
                    <md-tooltip md-direction="top">
                      {{ $translate(change_type === deleted ? "restore" : "delete") }}
                      {{ $translate("menu_content.menu_item") }}
                    </md-tooltip>
                  </md-button>

                  <!-- Only show if entity isnt a direct link -->
                  <md-speed-dial
                    v-if="!category.includes('?') && can_user_manage_content && sub_items"
                    md-effect="scale"
                    md-direction="bottom"
                    class="menu-content__small-speed-dial"
                    @click.native="select_from_entity_clicked(index, category, parent_category)"
                  >
                    <md-speed-dial-target class="md-plain">
                      <md-icon>add</md-icon>
                    </md-speed-dial-target>
                  </md-speed-dial>

                  <md-card-expand-trigger v-if="sub_items">
                    <md-button class="md-icon-button">
                      <md-icon>keyboard_arrow_down</md-icon>
                    </md-button>
                  </md-card-expand-trigger>
                </div>
              </md-card-actions>
            </md-card-header>

            <md-card-expand v-if="sub_items">
              <md-card-expand-content>
                <md-card-content>
                  <div
                    v-for="({ change_type: sub_change_type, ...sub_rest }, sub_index) in sub_items"
                    :key="`header-item-sub-${sub_index}-${index}`"
                    class="menu-content__sub-items"
                  >
                    <div class="menu-content__sub-items__inputs">
                      <md-field>
                        <label>{{ $translate("title_translation") }}</label>
                        <md-input
                          :value="get_correct_translation_value(sub_rest, 'name', [selected_language], false)"
                          @change="event => update_menu_item('name', [index, event.target.value, sub_index, selected_language])"
                          :disabled="sub_change_type === deleted || !can_user_manage_content"
                        />
                      </md-field>
                      <!-- Disabling possibility to adjust route -->
                      <!-- <md-field>
                        <label>{{ $translate("route") }}</label>
                        <md-input
                          :value="sub_category"
                          @change="event => update_menu_item('category', [index, event.target.value, sub_index])"
                          :disabled="sub_change_type === deleted || !can_user_manage_content"
                        />
                      </md-field> -->
                    </div>
                    <md-button
                      v-if="can_user_manage_content"
                      class="md-icon-button md-raised menu-content__sub-items__button"
                      @click="delete_or_restore([index, sub_index])"
                      :class="{
                        'md-primary': sub_change_type === deleted,
                        'md-accent': sub_change_type !== deleted
                      }"
                    >
                      <md-icon>{{ sub_change_type === deleted ? "restore" : "delete"}}</md-icon>
                      <md-tooltip md-direction="top">{{ $translate(sub_change_type === deleted ? "restore" : "delete") }} {{ $translate("menu_content.menu_subitem") }}</md-tooltip>
                    </md-button>
                  </div>
                </md-card-content>
              </md-card-expand-content>
            </md-card-expand>
          </md-card>
        </transition-group>
      </draggable>

      <md-button
        v-if="can_user_manage_content"
        class="menu-content__button md-fab md-raised md-primary"
        @click.native="open_products_modal = true"
      >
        <md-tooltip md-direction="left">{{ $translate("menu_content.add_item") }}</md-tooltip>
        <md-icon data-cy="add-menu">add</md-icon>
      </md-button>
    </div>

    <entity_selection_modal
      v-if="can_user_manage_content"
      :modal_title="$translate('menu_content.modal_title')"
      :open_modal="open_products_modal"
      :index_or_id_of_selected_entity="index_of_parent_entity"
      :index_of_selected_header_item="index_of_menu_item"
      @entity_selected="entity_selected"
      @collection_selected="index => selected_collection = index"
      @toggle_modal="toggle_modal"
    />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
import draggable from "vuedraggable"
import url_enity_encoder from "urlencode"
import { COUPLE, SINGLE, MULTI } from "../../../../../data/product_variety_types"
import { ADMIN, CONFIGURATION_STORE, CONTENT_STORE, deleted, imported, PRODUCTS_STORE, shopify, USER_STORE } from "../../../constants/others_constants"
import { CONTENT_DATA_UPDATED } from "../../../stores/Admin/constants"
import {
  ADD_NEW_MENU_ITEM,
  ADD_NEW_MENU_SUB_ITEM,
  UPDATE_MENU_ITEM_NAME,
  DELETE_OR_RESTORE_MENU_ITEM,
  UPDATE_MENU_ITEMS,
} from "../../../stores/Admin/content/constants"
import validator from "../../../methods/validations/menu_content"
import entity_selection_modal from "../entity_selection_modal"
import { collection, product } from "../../../../Shared/constants/other"
import get_curly_brace_content from "../../../../Shared/methods/get_curly_brace_content"
import replace_dashes_for_spaces from "../../../../Shared/methods/replace_dashes_for_spaces"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import language_switcher from "../language_switcher"
import video_tutorial from "../video_tutorial"
import { event_hub } from "../../../../../main"

export default {
  props: {
    can_user_manage_content: {
      type: Boolean,
      required: true
    }
  },
  components: {
    entity_selection_modal,
    language_switcher,
    draggable,
    video_tutorial
  },
  data() {
    return {
      open_products_modal: false,
      open_header_content_index: "",
      average_width_of_header_item_per_letter: 12.5,
      selected_collection: 0,
      index_of_menu_item: undefined,
      index_of_parent_entity: undefined,
      selected_language: "",
      COUPLE,
      SINGLE,
      MULTI,
      deleted
    };
  },
  computed: {
    ...mapState(CONTENT_STORE, ["header_items"]),
    ...mapState(PRODUCTS_STORE, [
      "imported_collections",
      "imported_products",
      "shopify_collections",
      "shopify_products"
    ]),
    ...mapState(CONFIGURATION_STORE, ["shopify_config", "translations"]),
    ...mapState(USER_STORE, ["permissions"]),
    get_header_items: {
      get() {
        return this.header_items
      },
      set(updated_positioning) {
        this.update_menu_items(updated_positioning)
        this.content_data_updated({ types_updated: ["header_items"] })
      }
    },
    products_without_collection() {
      return (this[`${this.products_type}_products`] || []).filter(({ collections }) => !collections || !collections.length)
    },
    products_type() {
      return this.shopify_config.should_use_shopify_data ? shopify : imported
    },
    collections() {
      return [
        ...(this[`${this.products_type}_collections`] || []),
        ...(
          this.products_without_collection.length ?
          [{
            title: this.$translate("products.parent_collections.without_collection"),
            products: this.products_without_collection
          }] : []
        )
      ]
    },
    get_all_products() {
      return this[`${this.products_type}_products`] || []
    }
  },
  watch: {
    open_products_modal(val) {
      if (!val) this.index_of_parent_entity = undefined
    }
  },
  mounted() {
    this.$nextTick(() => event_hub.$emit("toggle_mobile_menu"))
  },
  methods: {
    ...mapMutations(CONTENT_STORE, {
      add_new_menu_sub_item: ADD_NEW_MENU_SUB_ITEM,
      update_menu_item_name: UPDATE_MENU_ITEM_NAME,
      delete_or_restore_menu_item: DELETE_OR_RESTORE_MENU_ITEM,
      update_menu_items: UPDATE_MENU_ITEMS
    }),
    ...mapActions(CONTENT_STORE, {
      add_new_menu_item: ADD_NEW_MENU_ITEM,
    }),
    ...mapMutations(ADMIN, {
      content_data_updated: CONTENT_DATA_UPDATED
    }),
    get_correct_translation_value,
    toggle_modal(val) {
      this.open_products_modal = val
      this.index_of_menu_item = undefined
    },
    find_collections_of_parent(parent_collection) {
      return this.collections.filter(collection => {
        const parents = get_curly_brace_content(get_correct_translation_value(collection, "body_html"))

        return parents && parents[1] === parent_collection
      })
    },
    find_products_of_collection(collection_id) {
      return this.get_all_products.filter(({ collections }) => collections && collections.find(({ id }) => collection_id === id))
    },
    add_menu_item(index_of_menu_item) {
      if (index_of_menu_item >= 0) this.add_new_menu_sub_item([index_of_menu_item])
      else this.add_new_menu_item()

      this.content_data_updated({ types_updated: ["header_items"] })
      this.validate()
    },
    add_menu_item_from_entity(name, category, sub_category, sub_items, has_parent_collection, entity_id) {
      if (this.index_of_menu_item >= 0) this.add_new_menu_sub_item([
        this.index_of_menu_item,
        name,
        sub_category,
        Object.keys(this.translations)
      ])
      else this.add_new_menu_item([name, category, sub_items, has_parent_collection, entity_id])

      this.index_of_menu_item = undefined
      this.index_of_parent_entity = undefined
      this.content_data_updated({ types_updated: ["header_items"] })
      this.validate()
    },
    update_menu_item(key, payload) {
      this[`update_menu_item_${key}`](payload)
      this.content_data_updated({ types_updated: ["header_items"] })
      this.validate()
    },
    delete_or_restore(payload) {
      this.delete_or_restore_menu_item(payload)
      this.content_data_updated({ types_updated: ["header_items"] })
      this.validate()
    },
    entity_selected(selected_collection, selected_product, direct_to_entity, selecting_collection, selecting_parent_collection) {
      const has_parent_collection = get_curly_brace_content(get_correct_translation_value(selected_collection, "body_html"))

      /**
       * 1. If creating menu button linking directly to a SINGLE or COUPLE product page
       */
      if (direct_to_entity) this.add_menu_item_from_entity(
        get_correct_translation_value(selecting_collection ? selected_collection : selected_product, "title"),
        `?${selecting_collection ? collection : product}=${
          (selecting_collection ? selected_collection.handle : (selected_product.buffer_id || selected_product.id))
        }`,
        undefined,
        undefined
      )
      /**
       * 2. If creating a link for parent collection and its collections
       */
      else if (selecting_parent_collection) this.add_menu_item_from_entity(
        replace_dashes_for_spaces(url_enity_encoder.decode(has_parent_collection[1])),
        has_parent_collection[1],
        selected_collection.handle,
        this.find_collections_of_parent(has_parent_collection[1]).map(collection => ({
          sub_category: collection.handle,
          id: collection.id,
          translations: Object.keys(this.translations).reduce((tot, lang) => ({
            ...tot,
            [lang]: {
              name: get_correct_translation_value(collection,  "title")
            }
          }), {})
        })),
        has_parent_collection
      )
      /**
       * 3. If creating a link for collection and its products
       */
      else if (selecting_collection) this.add_menu_item_from_entity(
        get_correct_translation_value(selected_collection, "title"),
        `${has_parent_collection ? `${has_parent_collection[1]}/` : ""}${selected_collection.handle}`,
        selected_collection.handle,
        this.find_products_of_collection(selected_collection.id).map(product => ({
          sub_category: product.id,
          translations: Object.keys(this.translations).reduce((tot, lang) => ({
            ...tot,
            [lang]: {
              name: get_correct_translation_value(product,  "title")
            }
          }), {})
        })),
        undefined,
        selected_collection.id
      )
      /**
       * 4. If creating a menu link directly to a product
       */
      else {
        this.add_menu_item_from_entity(
          get_correct_translation_value(selected_product, "title"),
          `${has_parent_collection ? `${has_parent_collection[1]}/` : ""}${
            selected_collection.handle}/${selected_product.buffer_id || selected_product.id
          }`,
          undefined,
          undefined
        )
      }
      this.content_data_updated({ types_updated: ["header_items"] })

      this.open_products_modal = false
      this.selected_collection = 0
      this.validate()
    },
    select_from_entity_clicked(index, category, parent_category) {
      this.index_of_menu_item = index
      this.open_products_modal = true
      this.index_of_parent_entity = this.find_index_of_collection(category, parent_category)
    },
    /**
     * If finding index of parent collection, it returns an array
     * of indexes of collections under the selected parent collection.
     * Else, it returns index of the collection for the products, if
     * however the collection has a parent collection, it first erases the
     * parrent collection handle included in the category (route) of the menu item
     */
    find_index_of_collection(category, is_parent_category) {
      return is_parent_category ?
        this.collections.reduce((indexes, { body_html }, ind) => {
          if (
            body_html &&
            get_curly_brace_content(body_html) &&
            get_curly_brace_content(body_html)[1] === category
          ) indexes.push(ind)

          return indexes
        }, []) :
        this.collections.findIndex(({ title, handle, body_html }) => {
          // If collection for products without collection, return false
          if (this.$translate("products.parent_collections.without_collection") === title) return false
          if (get_curly_brace_content(body_html)) category = category.replace(`${get_curly_brace_content(body_html)[1]}/`, "")

          return category === handle
        })
    },
    ...validator
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/flag_change.scss";

  .menu-content {
    position: relative;

    .md-headline {
      margin-top: 0;
    }

    .md-card {
      width: 320px;
      margin: 4px;
      display: inline-block;
      vertical-align: top;
    }

    &__button {
      display: block !important;
      margin: $default-size auto !important;
    }

    &__items {
      .md-card-header {
        padding-top: 0;
        padding-bottom: 0;
        cursor: move;
      }

      .md-card-actions {
        display: block;
      }

      .md-card-expand-trigger {
        float: right;
      }

      .md-field {
        margin: 0;
      }

      &__buttons {
        padding-top: $half-default-size;
        position: relative;
      }

      .md-card {
        @media (max-width: $mobile--large) {
          margin: 0 3px $half-default-size !important;
          width: calc(100% - 6px);
        }
      }
    }

    &__other-settings {
      .md-checkbox {
        margin: 0 $material-size 0 0;
      }
    }

    &__sub-items {
      padding-bottom: $default-size;
      margin-bottom: $half-default-size;
      border-bottom: 1px solid $pure-black;

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border: unset;
      }

      &__inputs {
        display: inline-block;
        width: calc(100% - 55px);
        vertical-align: middle;
      }

      &__button {
        display: inline-block !important;
        margin: 0 0 0 $half-default-size !important;
        vertical-align: middle !important;
      }
    }

    &__small-speed-dial {
      position: absolute;
      top: $half-default-size;
      left: $material-button-small;
      transition: 250ms ease-in-out;
      z-index: 10;

      .md-fab {
        width: $double-default-size;
        height: $double-default-size;
      }

      .md-speed-dial-content {
        display: none;

        .md-button {
          margin: $half-default-size 0 0;
        }
      }

      &:hover {
        .md-speed-dial-content {
          display: flex;
        }
      }
    }

    .menu {
      position: relative !important;
      height: $menu-height;
      margin: $default-size 0 $double-default-size !important;
    }
  }
</style>
